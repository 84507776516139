import type React from "react"
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid"
import { ButtonOutline } from "~/common/ui/Buttons"
import { Can, Role } from "~/common/ui/Can"
import type { Context } from "~/models/Context"
import { isDev } from "~/common/utils/stages"
import { openInNewTabConfirm, openInNewTab } from "~/common/utils/opener"

const requiredRoleFor =
  ({
    isBillingRoot,
    organisationAlias,
  }: {
    isBillingRoot: boolean
    organisationAlias: string
  }) =>
  (memberAccountName: string, customerType: string): Role => {
    if (isBillingRoot && customerType !== "DIRECT") {
      return Role.finance
    }

    if (
      organisationAlias === "juma" &&
      (memberAccountName === "billing" ||
        memberAccountName.startsWith("billing-central"))
    ) {
      return Role.finance
    }

    return Role.customersupport
  }

type ConfirmButtonProps = {
  awsSupportCaseStaxManaged?: boolean
  context: Context
  customerType?: string
  isBillingRoot?: boolean
  memberAccountName: string
  organisationAlias?: string
  organisationName?: string
  targetURL: string
  text?: string
  tooltip?: string
}

const ExternalLinkIcon = () => (
  <ArrowTopRightOnSquareIcon
    style={{
      marginLeft: "4px",
      fontSize: "1.15rem",
      height: "16px",
      width: "16px",
    }}
  />
)

export const LoginToAwsButton = (props: ConfirmButtonProps) => {
  const {
    targetURL,
    memberAccountName,
    customerType = "none",
    organisationName = "none",
    isBillingRoot = false,
    organisationAlias = "none",
    awsSupportCaseStaxManaged = false,
    text = "Login",
    tooltip = "Open in AWS Console",
  } = props

  // confirmation is skipped according to these conditions
  const isConfirmationSkipped = (): boolean => {
    if (isDev()) {
      return true
    }
    if (organisationName === "jumaroot") {
      return true
    }
    if (customerType === "SPAM") {
      return true
    }
    if (customerType === "ECAM" && isBillingRoot) {
      return true
    }
    if (customerType === "DIRECT") {
      return false
    }
    if (awsSupportCaseStaxManaged) {
      return true
    }
    return awsSupportCaseStaxManaged
  }

  const requiredRole = requiredRoleFor({ isBillingRoot, organisationAlias })

  return (
    <Can
      currentUser={props.context.currentUser}
      requiredRole={requiredRole(memberAccountName, customerType)}
      yes={
        <ButtonOutline
          onClick={() =>
            isConfirmationSkipped()
              ? openInNewTab(targetURL)
              : openInNewTabConfirm(targetURL)
          }
          title={tooltip}
          variant={isConfirmationSkipped() ? "Primary" : "Danger"}
        >
          {text} <ExternalLinkIcon />
        </ButtonOutline>
      }
      no={
        <DisabledLoginToAwsButton tooltip="You don't have permission to login to AWS Console" />
      }
    />
  )
}

type RequestButtonProps = {
  context: Context
  customerType?: string
  isBillingRoot?: boolean
  memberAccountName: string
  onClick: () => void
  organisationAlias?: string
  text?: string
  tooltip?: string
}

export const RequestLoginToAwsButton = (props: RequestButtonProps) => {
  const {
    memberAccountName,
    onClick,
    customerType = "none",
    isBillingRoot = false,
    organisationAlias = "none",
    text = "Request Access",
    tooltip = "Admin access expired",
  } = props

  const requiredRole = requiredRoleFor({ isBillingRoot, organisationAlias })

  return (
    <Can
      currentUser={props.context.currentUser}
      requiredRole={requiredRole(memberAccountName, customerType)}
      yes={
        <ButtonOutline onClick={onClick} title={tooltip} variant="Secondary">
          {text}
        </ButtonOutline>
      }
      no={
        <DisabledLoginToAwsButton tooltip="You don't have permission to login to AWS Console" />
      }
    />
  )
}

type DisabledButtonProps = {
  text?: string
  tooltip: string
}

const DisabledLoginToAwsButton: React.FC<DisabledButtonProps> = ({
  text = "Login",
  tooltip,
}) => (
  <ButtonOutline variant="Secondary" disabled={true} title={tooltip}>
    {text} <ExternalLinkIcon />
  </ButtonOutline>
)
