import * as routes from "../../routes"
import type React from "react"
import type { Customer } from "~/models/Customer"
import { Link } from "~/common/ui/Link"
import { Panel } from "~/common/ui/Panels"
import { Status } from "~/common/ui/Status"
import { StaxLogo } from "~/common/ui/StaxLogo"

type Props = {
  headers: Array<string>
  customers: Array<Customer>
}

type Empty = Record<never, never>

const renderCustomers = (_props: Props, customers: Array<Customer>) => {
  return customers.map((customer: Customer) => {
    const route = routes.routeCustomer(customer.Id)
    const customerHref = routes.generate(route)
    const link = routes.generate(route)

    // Make all cells clickable, except the "More actions" and "run factory"
    const ClickableCell = (props: React.PropsWithChildren<Empty>) => (
      <td className="group-hover:bg-slate-100">
        <Link
          href={customerHref}
          className="inline-block w-full hover:underline"
          title={`View customer details (${customer.Name})`}
        >
          {props.children}
        </Link>
      </td>
    )

    return (
      <tr key={customer.Id} className="group">
        <ClickableCell>
          <Link href={link}>
            <span className="font-semibold">{customer.Name}</span>{" "}
            {customer.Internal && (
              <span title="Stax-managed Internal Customer">
                <StaxLogo className="w-4 inline-block ml-1" />
              </span>
            )}
          </Link>
        </ClickableCell>

        <ClickableCell>{customer.SupportPlan}</ClickableCell>

        <ClickableCell>{customer.Type}</ClickableCell>

        <ClickableCell>
          <Status status={customer.Status} />
        </ClickableCell>
      </tr>
    )
  })
}

export const CustomerTable = (props: Props) => {
  const { headers, customers } = props

  return (
    <Panel>
      <table className="DataTable">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>{renderCustomers(props, customers)}</tbody>
      </table>
    </Panel>
  )
}
