import * as times from "~/common/utils/times"
import React from "react"
import type { AccessRequest } from "~/common/api/organisationsApiV2"
import type { Account } from "~/models/Account"
import { ButtonOutline } from "~/common/ui/Buttons"
import type { Context } from "~/models/Context"
import { FormTextInput, FormSelect } from "~/common/ui/Forms"
import { type DateTime, Number as ENumber, Option } from "effect"
import type { Organisation } from "~/models/Organisation"
import {
  type IdamConfig,
  getAdminAccessRolesTTLInHours,
  getAdminAccessRolesTTLInHoursAccount,
} from "~/models/IdamConfig"
import {
  LoginToAwsButton,
  RequestLoginToAwsButton,
} from "~/common/ui/ConfirmLinkButton"
import { currentStageId } from "~/common/utils/stages"
import { IsAny, Role } from "~/common/ui/Can"
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalFooter,
} from "~/common/ui/Modals"

type State = {
  duration: number
  isOpen: boolean
  reason: string
}

type Props = {
  account: Account
  context: Context
  loginsRequested: Set<string>
  onRequestLogin: (request: AccessRequest) => void
  organisation: Organisation
  organisationIdamConfigs: ReadonlyArray<IdamConfig>
}

const ADMIN_ACCESS_EXEMPT_ALIASES = ["juma", "smoko"]
const DURATIONS = [30, 60, 90, 120]

const getBaseState = (): State => {
  const duration = DURATIONS[0] ?? 30

  return {
    duration,
    isOpen: false,
    reason: "",
  }
}

const isBillingRoot = (account: Account) => !account.BillingAccountId

// If an account is either created by stax OR a billing root it is "Stax Managed"
const isStaxManaged = (account: Account): boolean => {
  return !!account.StaxCreated || isBillingRoot(account)
}

const parseISOOrNull = (date: string): DateTime.DateTime | null => {
  return Option.getOrNull(times.parseISO(date))
}

type AdminAccessExpiredReturns = {
  accountAdminAccessExpired: boolean
  adminAccessExpired: boolean
  orgAdminAccessExpired: boolean
}

const getAccountAdminAccessExpired = (args: {
  now: DateTime.DateTime
  accountCreatedAt: DateTime.DateTime | null
  accountAdminAccessRolesTTLInHours: number
}): boolean => {
  if (args.accountCreatedAt == null) return true

  const expiry = times.add(args.accountCreatedAt, {
    hours: args.accountAdminAccessRolesTTLInHours,
  })

  return times.isAfter(args.now, expiry)
}

const getOrgAdminAccessExpired = (args: {
  now: DateTime.DateTime
  organisationCreatedAt: DateTime.DateTime | null
  orgAdminAccessRolesTTLInHours: number
}): boolean => {
  if (args.organisationCreatedAt == null) return true

  const expiry = times.add(args.organisationCreatedAt, {
    hours: args.orgAdminAccessRolesTTLInHours,
  })

  return times.isAfter(args.now, expiry)
}

export const getAdminAccessExpired = (args: {
  accountAdminAccessRolesTTLInHours: number
  accountCreatedAt: DateTime.DateTime | null
  now: DateTime.DateTime
  orgAdminAccessRolesTTLInHours: number
  organisationCreatedAt: DateTime.DateTime | null
}): AdminAccessExpiredReturns => {
  const {
    accountAdminAccessRolesTTLInHours,
    accountCreatedAt,
    now,
    orgAdminAccessRolesTTLInHours,
    organisationCreatedAt,
  } = args

  const accountAdminAccessExpired = getAccountAdminAccessExpired({
    now,
    accountCreatedAt,
    accountAdminAccessRolesTTLInHours,
  })

  const orgAdminAccessExpired = getOrgAdminAccessExpired({
    now,
    organisationCreatedAt,
    orgAdminAccessRolesTTLInHours,
  })

  const adminAccessExpired = accountAdminAccessExpired && orgAdminAccessExpired

  return {
    accountAdminAccessExpired,
    adminAccessExpired,
    orgAdminAccessExpired,
  }
}

export const MaybeLogin = (props: Props) => {
  const { account, context, organisation } = props

  const [state, setState] = React.useState<State>(getBaseState())

  const hasRequestedAccess = props.loginsRequested.has(account.Id)

  const handleClose = () => setState(getBaseState())

  const handleChangeDuration = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const value = event.currentTarget.value

    const duration = Option.getOrElse(ENumber.parse(value), () => 30)

    setState({
      ...state,
      duration,
    })
  }

  const handleChangeReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, reason: event.currentTarget.value })
  }

  const handleStartRequest = () => setState({ ...state, isOpen: true })

  const handleSubmit = () => {
    const { duration, reason } = state
    // validate
    if (!duration || !reason) return

    props.onRequestLogin({
      orgId: organisation.Id,
      accessDurationInMins: state.duration,
      reason: state.reason,
      requestor: context.currentUser.id,
      staxAccountId: account.Id,
    })

    setState(getBaseState())
  }

  const accountAdminAccessRolesTTLInHours = Option.getOrElse(
    getAdminAccessRolesTTLInHoursAccount(
      props.organisationIdamConfigs,
      account.Id,
    ),
    () => 0,
  )

  const orgAdminAccessRolesTTLInHours = Option.getOrElse(
    getAdminAccessRolesTTLInHours(props.organisationIdamConfigs),
    () => 0,
  )

  const accountCreatedAt = parseISOOrNull(account.CreatedTS)

  const organisationCreatedAt = parseISOOrNull(organisation.CreatedTS)

  const now = times.now()

  const { adminAccessExpired, accountAdminAccessExpired } =
    getAdminAccessExpired({
      accountAdminAccessRolesTTLInHours,
      accountCreatedAt,
      now,
      orgAdminAccessRolesTTLInHours,
      organisationCreatedAt,
    })

  const adminAccessExempt =
    currentStageId().includes("dev") ||
    (organisation.Alias &&
      ADMIN_ACCESS_EXEMPT_ALIASES.includes(organisation.Alias)) ||
    account.AccountRole === "MANAGEMENT_RESELL"

  const awsLoginURLRegex =
    /(.+)(\/auth\/realms\/master\/protocol\/saml\/clients\/)(\d+)/

  const supportAwsLoginURL = isStaxManaged(account)
    ? account.AwsLoginURL.replace(awsLoginURLRegex, "$1$2support-$3")
    : account.AwsLoginURL

  const loginText = hasRequestedAccess ? "Requested" : "Login"

  const loginTip = hasRequestedAccess
    ? "Open in AWS Console (access may not be provisioned for up to 5 minutes)"
    : "Open in AWS Console"

  const accountName = Option.getOrElse(account.Name, () => "N/A")

  const LoginButton = ({ targetURL }: { targetURL: string }) => (
    <LoginToAwsButton
      context={props.context}
      customerType={organisation.OrganisationType}
      isBillingRoot={isBillingRoot(account)}
      memberAccountName={accountName}
      organisationAlias={organisation.Alias}
      organisationName={organisation.Name}
      targetURL={targetURL}
      text={loginText}
      tooltip={loginTip}
    />
  )

  const showRequestAccess =
    adminAccessExpired && !adminAccessExempt && !hasRequestedAccess

  return (
    <>
      {showRequestAccess ? (
        <RequestLoginToAwsButton
          context={props.context}
          customerType={organisation.OrganisationType}
          isBillingRoot={isBillingRoot(account)}
          memberAccountName={accountName}
          organisationAlias={organisation.Alias}
          tooltip={
            accountAdminAccessRolesTTLInHours && accountAdminAccessExpired
              ? `Admin access expired (${account.CreatedTS} + ${accountAdminAccessRolesTTLInHours} hours)`
              : `Admin access expired (${organisation.CreatedTS} + ${orgAdminAccessRolesTTLInHours} hours)`
          }
          onClick={handleStartRequest}
        />
      ) : (
        <IsAny
          currentUser={context.currentUser}
          requiredRoles={[Role.customersupport]}
          yes={<LoginButton targetURL={supportAwsLoginURL} />}
          no={<LoginButton targetURL={account.AwsLoginURL} />}
        />
      )}
      <Modal
        isOpen={state.isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <ModalTitle id="form-dialog-title">Request Account Access</ModalTitle>
        <ModalContent>
          <div className="space-y-3 pb-4">
            <p>This account is owned by the customer, not Stax.</p>
            <p>
              You should not log into this account without permission from the
              customer.
            </p>
            <p>Logging into this account will trigger an INCIDENT.</p>
            <p>Are you sure you want to log into this customer account?</p>
            <p>
              If you are sure, select the desired access duration and provide a
              link to the support ticket below.
            </p>
            <p>
              Please wait up to 5 minutes for access to be provisioned (login
              button may be displayed before provisioning is complete)
            </p>
          </div>

          <FormTextInput
            id="input-aws-account"
            disabled
            label="AWS Account"
            value={`${account.Name} - ${account.AwsAccountId}`}
          />

          <FormTextInput
            id="input-requester"
            disabled
            label="Requester"
            value={`${context.currentUser.email} - ${context.currentUser.id}`}
          />

          <FormSelect
            id="duration-label"
            label="Duration in minutes"
            name="duration-label"
            onChange={handleChangeDuration}
            value={state.duration.toString()}
          >
            {DURATIONS.map((d) => (
              <option value={d} key={d}>
                {d}
              </option>
            ))}
          </FormSelect>

          <FormTextInput
            id="reason"
            label="Support ticket ID"
            onChange={handleChangeReason}
            placeholder="ID of support ticket or Jira card"
            required
            type="text"
            value={state.reason}
          />
        </ModalContent>

        <ModalFooter>
          <ButtonOutline onClick={handleClose} variant="Secondary">
            Cancel
          </ButtonOutline>
          <ButtonOutline onClick={handleSubmit}>Request</ButtonOutline>
        </ModalFooter>
      </Modal>
    </>
  )
}
