const STAX_DOMAINS = [
  ".juma", //intentionally not ".juma.cloud" to match local dev "admin.juma:3000"
  "staxapp.cloud",
]

const STAX_PREFIX = "stax-"

export function currentStageId() {
  const currentStage = window.AWS_CONFIG.Juma.stage
  const currentDomain = window.AWS_CONFIG.Juma.domainName

  const isStax = STAX_DOMAINS.some((domain) => currentDomain.includes(domain))

  if (isStax) {
    // Prods are already prefixed
    if (currentStage.includes(STAX_PREFIX)) return currentStage

    return `${STAX_PREFIX}${currentStage}`
  }

  // const isPing = STAX_DOMAINS.some(
  //   (domain) => currentDomain.includes(domain)
  // )

  return currentStage
}

export function isDev() {
  const currentStage = currentStageId()
  return currentStage.includes("dev") || currentStage.includes("test")
}

// TODO: it would be good to get this from a single source of truth eventually
export const STAGES = [
  {
    link: "https://admin.core.dev.juma.cloud/",
    stage: `${STAX_PREFIX}dev`,
    // stage: 'dev',
  },
  {
    link: "https://admin.core.test.juma.cloud/",
    stage: `${STAX_PREFIX}test`,
    // stage: 'test',
  },
  {
    link: "https://admin.au1.staxapp.cloud/",
    stage: `${STAX_PREFIX}au1`,
    // stage: 'au1',
  },
  {
    link: "https://admin.us1.staxapp.cloud/",
    stage: `${STAX_PREFIX}us1`,
    // stage: 'us1',
  },
  {
    link: "https://admin.eu1.staxapp.cloud/",
    stage: `${STAX_PREFIX}eu1`,
    // stage: 'eu1',
  },
]
