import { Schema } from "@effect/schema"

export const UserSchema = Schema.Struct({
  Email: Schema.String,
  EmailVerified: Schema.OptionFromNullishOr(Schema.Boolean, undefined),
  Enabled: Schema.Boolean,
  Federated: Schema.Boolean,
  FirstName: Schema.String,
  Id: Schema.String,
  LastName: Schema.String,
  MFA: Schema.OptionFromNullishOr(Schema.Boolean, undefined),
})

export type User = typeof UserSchema.Type

export const UsersResponseSchema = Schema.Struct({
  Users: Schema.Array(UserSchema),
})
