import * as accountsApi from "~/common/api/accountsApiV2"
import * as log from "~/common/utils/log"
import React from "react"
import {
  type AccessRequest,
  requestAccountAccess,
} from "~/common/api/organisationsApiV2"
import type { Account } from "~/models/Account"
import { Can, Role } from "~/common/ui/Can"
import { ClickToCopy } from "~/common/ui/ClickToCopy"
import type { Context } from "~/models/Context"
import { FactoryButton } from "~/common/ui/FactoryButton"
import type { IdamConfig } from "~/models/IdamConfig"
import { Loading } from "~/common/ui/Loading"
import { MaybeLogin } from "./CustomerAndOrgDetails/MaybeLogin"
import { NoResults } from "~/common/ui/NoResults"
import { Option } from "effect"
import type { Organisation } from "~/models/Organisation"
import { Status } from "~/common/ui/Status"
import { StaxLogo } from "~/common/ui/StaxLogo"
import {
  useElmish,
  cmd,
  type InitResult,
  type UpdateReturnType,
} from "react-elmish"
import { useOrganisationAccounts } from "~/common/hooks/useOrganisationAccounts"

const headers = [
  { key: "ids", title: "IDs" },
  { key: "cost", title: "Cost" },
  { key: "status", title: "Status" },
  { key: "factoryVer", title: "Factory Ver." },
  { key: "action", title: "Action" },
  { key: "awsLogin", title: "AWS Login" },
]

const isMemberAccount = (account: Account) => {
  return Option.match(account.BillingAccountId, {
    onSome: (billingAccountId) => billingAccountId !== "",
    onNone: () => false,
  })
}

const isBillingRoot = (account: Account) => {
  return !isMemberAccount(account)
}

// If an account is either created by stax OR a billing root it is "Stax Managed"
const isStaxManaged = (account: Account): boolean => {
  return !!account.StaxCreated || isBillingRoot(account)
}

type Message =
  | {
      name: "RequestedLogin"
      request: AccessRequest
    }
  | {
      name: "RequestedLoginDone"
      request: AccessRequest
    }
  | {
      name: "RequestedLoginFailed"
      request: AccessRequest
    }

type Model = {
  loginsRequested: Set<string>
}

type InitProps = Record<never, never>

const init = (_props: InitProps): InitResult<Model, Message> => {
  return [
    {
      loginsRequested: new Set(),
    },
  ]
}

const update = (
  model: Model,
  msg: Message,
  _props: InitProps,
): UpdateReturnType<Model, Message> => {
  switch (msg.name) {
    case "RequestedLogin": {
      const { request } = msg

      const onSuccess = (): Message => ({
        name: "RequestedLoginDone",
        request,
      })

      const onFailure = (): Message => ({
        name: "RequestedLoginFailed",
        request,
      })

      const command = cmd.ofEither(
        () => requestAccountAccess(request),
        onSuccess,
        onFailure,
      )

      return [model, command]
    }

    case "RequestedLoginDone": {
      const { request } = msg

      return [
        {
          ...model,
          loginsRequested: model.loginsRequested.add(request.staxAccountId),
        },
      ]
    }

    case "RequestedLoginFailed": {
      return [model]
    }
  }
}

type Props = {
  context: Context
  organisation: Organisation
  organisationIdamConfigs: ReadonlyArray<IdamConfig>
}

const ButtonDiscovery = (props: { account: Account; disabled: boolean }) => {
  const { account, disabled = false } = props
  const buttonDisabled = account.Status !== "DISCOVERED" || disabled

  const runDiscovery = React.useCallback(() => {
    const orgId = Option.getOrNull(account.OrganisationId)

    if (orgId) {
      return accountsApi.rediscover(orgId, account.AwsAccountId)
    }

    return undefined
  }, [account.OrganisationId, account.AwsAccountId])

  return (
    <FactoryButton
      disabled={buttonDisabled}
      key="factory-re-discovery"
      label="Run Discovery"
      onClick={runDiscovery}
      successMessage="Queued"
    />
  )
}

const ButtonSaga = (props: { account: Account; disabled: boolean }) => {
  const { account, disabled = false } = props

  const runReonboard = React.useCallback(() => {
    return accountsApi.reonboard(account.Id)
  }, [account.Id])

  const runSaga = React.useCallback(() => {
    return accountsApi.invokeSaga(account.Id)
  }, [account.Id])

  if (account.Status === "ERROR") {
    if (account.AssuranceState === "NONE") {
      // discovery failure
      return <ButtonDiscovery account={account} disabled={disabled} />
    }

    if (
      account.AssuranceState !== "NONE" &&
      account.AssuranceState !== "UPDATING"
    ) {
      // onboarding failure
      return (
        <FactoryButton
          disabled={disabled}
          key="factory-re-onboard"
          label="Re-onboard"
          onClick={runReonboard}
          successMessage="Queued"
        />
      )
    }
  }

  const assuranceButtonDisabled =
    account.Status === "DISCOVERED" || account.Status === "ONBOARDING"

  if (!assuranceButtonDisabled) {
    return (
      <FactoryButton
        label="Run Assurance"
        disabled={disabled}
        key="factory"
        onClick={runSaga}
        successMessage="Queued"
        title="Runs Account Assurance for this Account"
      />
    )
  }

  return <ButtonDiscovery account={account} disabled={disabled} />
}

const getFormattedLatestCost = (account: Account): string => {
  return Option.match(account.LatestCost, {
    onSome: (cost) => `$${String(cost)}`,
    onNone: () => "$0.00",
  })
}

type RowProps = {
  account: Account
  context: Context
  loginsRequested: Set<string>
  onRequestLogin: (request: AccessRequest) => void
  organisation: Organisation
  organisationIdamConfigs: ReadonlyArray<IdamConfig>
}

const Row = (props: RowProps) => {
  const { account, organisation } = props
  const accountName = Option.getOrElse(account.Name, () => "N/A")
  const factoryVersion = Option.getOrElse(account.FactoryVersion, () => "N/A")

  return (
    <tr key={account.Id}>
      <td>
        {accountName}
        {isStaxManaged(account) && (
          <span title="Stax-managed Account">
            <StaxLogo className="ml-2 w-4 inline-block" />
          </span>
        )}
      </td>
      <td>
        <div>
          <small>
            AWS ID: {account.AwsAccountId}{" "}
            <ClickToCopy text={account.AwsAccountId} />
          </small>
        </div>
        <div>
          <small>
            Stax ID: {account.Id} <ClickToCopy text={account.Id} />
          </small>
        </div>
      </td>
      <td>{getFormattedLatestCost(account)}</td>
      <td>
        <Status status={account.Status} />
      </td>
      <td>
        <small>
          {factoryVersion} <ClickToCopy text={factoryVersion} />
        </small>
      </td>
      <td style={{ width: "15%" }}>
        <Can
          currentUser={props.context.currentUser}
          requiredRole={Role.deployer}
          yes={<ButtonSaga account={account} disabled={false} />}
          no={<ButtonSaga account={account} disabled={true} />}
        />
      </td>
      <td style={{ width: "15%" }}>
        <MaybeLogin
          account={account}
          context={props.context}
          loginsRequested={props.loginsRequested}
          onRequestLogin={props.onRequestLogin}
          organisation={organisation}
          organisationIdamConfigs={props.organisationIdamConfigs}
        />
      </td>
    </tr>
  )
}

export const CustomerAccountList = (props: Props) => {
  const { organisation } = props
  const organisationId = organisation.Id

  const [model, dispatch] = useElmish<InitProps, Model, Message>({
    props: {},
    init,
    update,
    name: "CustomerAccountList",
  })

  const { remoteData } = useOrganisationAccounts(organisationId)

  const onRequestLogin = React.useCallback(
    (request: AccessRequest) => {
      dispatch({ name: "RequestedLogin", request })
    },
    [dispatch],
  )

  const isLoading =
    remoteData._tag === "Loading" || remoteData._tag === "NotAsked"

  if (isLoading) {
    return <Loading classname="pb-12" />
  }

  if (remoteData._tag === "Failure") {
    log.error(remoteData.error)
    return <NoResults title="Error fetching Accounts" />
  }

  const { Accounts: accounts } = remoteData.data

  const memberAccounts = accounts.filter((account: Account) => {
    return !isBillingRoot(account)
  })

  const billingRootAccounts = accounts.filter((account: Account) => {
    return isBillingRoot(account)
  })

  return (
    <table className="DataTable">
      {billingRootAccounts.length > 0 && (
        <React.Fragment>
          <thead>
            <tr>
              <th>Billing roots</th>
              {headers.map((header, i) => (
                <th
                  key={header.key}
                  style={{ minWidth: i === 0 ? "20%" : "none" }}
                >
                  {header.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {billingRootAccounts.map((account) => (
              <Row
                account={account}
                context={props.context}
                key={account.Id}
                loginsRequested={model.loginsRequested}
                onRequestLogin={onRequestLogin}
                organisation={organisation}
                organisationIdamConfigs={props.organisationIdamConfigs}
              />
            ))}
          </tbody>
          <br />
          <br />
        </React.Fragment>
      )}
      <thead>
        <tr>
          <th>Member accounts</th>
          {headers.map((header, i) => (
            <th key={header.key} style={{ minWidth: i === 0 ? "20%" : "none" }}>
              {header.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {memberAccounts.map((account) => (
          <Row
            account={account}
            context={props.context}
            key={account.Id}
            loginsRequested={model.loginsRequested}
            onRequestLogin={onRequestLogin}
            organisation={organisation}
            organisationIdamConfigs={props.organisationIdamConfigs}
          />
        ))}
      </tbody>
    </table>
  )
}
