import * as middleware from "~/common/api/middleware"
import * as RemoteData from "~/models/RemoteData"
import React from "react"
import type { AmplifyResponse } from "~/common/api/apiFetchers"
import { ButtonOutline } from "~/common/ui/Buttons"
import { Toast } from "~/common/ui/Toast"

type Props = {
  disabled?: boolean
  label: string
  onClick?: () => Promise<AmplifyResponse> | undefined
  successMessage?: string
  title?: string
}

type Data = RemoteData.RemoteData<AmplifyResponse, unknown>

const Content = (props: { data: Data; label: string }) => {
  if (props.data._tag === "Success") {
    return `${props.label} (Done)`
  }

  if (props.data._tag === "Loading") {
    return "Loading..."
  }

  if (props.data._tag === "Failure") {
    return `${props.label} (Error)`
  }

  return props.label
}

const MaybeToast = (props: {
  data: Data
  successMessage?: string | undefined
}) => {
  const successMessage = props.successMessage ?? "Queued"

  if (props.data._tag === "Success") {
    return <Toast message={successMessage} variant="success" />
  }

  if (props.data._tag === "Failure") {
    return <Toast message="Something went wrong" variant="error" />
  }

  return null
}

export const FactoryButton = (props: Props) => {
  const [remoteData, setRemoteData] = React.useState<Data>(
    RemoteData.NotAsked(),
  )

  const handleOnClick = () => {
    if (props.onClick) {
      // undefined, means the action was cancelled
      const promise = props.onClick()

      if (promise) {
        setRemoteData(RemoteData.Loading())

        middleware.amplifyResponseToRemoteData(promise).then((remoteData) => {
          setRemoteData(remoteData)
        })
      }
    }
  }

  return (
    <>
      <ButtonOutline
        onClick={handleOnClick}
        disabled={props.disabled}
        title={props.title}
      >
        <Content data={remoteData} {...props} />
      </ButtonOutline>
      <MaybeToast data={remoteData} successMessage={props.successMessage} />
    </>
  )
}
