import { Option, Data, Either, pipe } from "effect"

export type RemoteData<Data, Err> = Data.TaggedEnum<{
  NotAsked: Record<never, never>
  Loading: Record<never, never>
  Failure: { readonly error: Err }
  Success: { readonly data: Data }
}>

interface RemoteDataDefinition extends Data.TaggedEnum.WithGenerics<2> {
  readonly taggedEnum: RemoteData<this["A"], this["B"]>
}

export const { NotAsked, Loading, Failure, Success } =
  Data.taggedEnum<RemoteDataDefinition>()

export const getOrElse = <Data, Err>(
  data: RemoteData<Data, Err>,
  fallback: () => Data,
): Data => {
  return pipe(data, toMaybe, Option.getOrElse(fallback))
}

export const map =
  <DataIn, DataOut, Err>(mapper: (data: DataIn) => DataOut) =>
  (data: RemoteData<DataIn, Err>): RemoteData<DataOut, Err> => {
    switch (data._tag) {
      case "NotAsked":
        return data
      case "Loading":
        return { _tag: "Loading" }
      case "Failure":
        return data
      case "Success":
        return { _tag: "Success", data: mapper(data.data) }
    }
  }

type Matcher<Data, Err, Out> = {
  onNotAsked: () => Out
  onLoading: () => Out
  onFailure: (error: Err) => Out
  onSuccess: (data: Data) => Out
}

export const match = <Data, Err, Out>(
  data: RemoteData<Data, Err>,
  matcher: Matcher<Data, Err, Out>,
): Out => {
  switch (data._tag) {
    case "NotAsked":
      return matcher.onNotAsked()
    case "Loading":
      return matcher.onLoading()
    case "Failure":
      return matcher.onFailure(data.error)
    case "Success":
      return matcher.onSuccess(data.data)
  }
}

export const toMaybe = <D>(data: RemoteData<D, unknown>): Option.Option<D> => {
  if (data._tag === "Success") {
    return Option.some(data.data)
  }

  return Option.none()
}

export const unwrapEither = <Data, Err>(
  data: RemoteData<Either.Either<Data, Err>, Err>,
): RemoteData<Data, Err> => {
  if (data._tag === "Success") {
    return Either.match(data.data, {
      onLeft: (error) => ({ _tag: "Failure", error }),
      onRight: (data) => ({ _tag: "Success", data }),
    })
  }

  return data
}
