import { Schema } from "@effect/schema"
import { Option } from "effect"

export type DeploymentStatus = "SUCCESS" | "ERROR" | "PENDING" | "IDLE"

export const CommentSchema = Schema.rename(
  Schema.Struct({
    Comment: Schema.String,
    CreatedTS: Schema.String,
    Id: Schema.String,
    ModifiedTS: Schema.String,
  }),
  {
    Comment: "comment",
    CreatedTS: "createdTS",
    Id: "id",
    ModifiedTS: "modifiedTS",
  },
)

export type Comment = typeof CommentSchema.Type

export const DeploymentSchema = Schema.rename(
  Schema.Struct({
    ChangeLog: Schema.String,
    CommitAuthor: Schema.String,
    ComponentName: Schema.String,
    CreatedTS: Schema.String,
    Id: Schema.String,
    ModifiedTS: Schema.String,
    PipelineSlug: Schema.String,
    Status: Schema.String,
    Version: Schema.String,
  }),
  {
    ChangeLog: "changeLog",
    CommitAuthor: "commitAuthor",
    ComponentName: "componentName",
    CreatedTS: "createdTS",
    Id: "id",
    ModifiedTS: "modifiedTS",
    PipelineSlug: "pipelineSlug",
    Status: "status",
    Version: "version",
  },
)

export type Deployment = typeof DeploymentSchema.Type

// Small version (ie Brief)
export const ComponentBriefSchema = Schema.rename(
  Schema.Struct({
    DeployPassing: Schema.Boolean,
    Id: Schema.String,
    Name: Schema.String,
  }),
  {
    DeployPassing: "deployPassing",
    Id: "id",
    Name: "name",
  },
)

export type ComponentBrief = typeof ComponentBriefSchema.Type

export const ComponentSchema = Schema.rename(
  Schema.Struct({
    ActiveDeployment: Schema.OptionFromNullishOr(DeploymentSchema, undefined),
    AvailableDeployments: Schema.OptionFromNullishOr(
      Schema.Array(DeploymentSchema),
      undefined,
    ),
    AutoDeploy: Schema.Boolean,
    Comments: Schema.OptionFromNullishOr(
      Schema.Array(CommentSchema),
      undefined,
    ),
    DeploymentId: Schema.String,
    DeploymentOrder: Schema.Number,
    DeployPassing: Schema.Boolean,
    Id: Schema.String,
    Locked: Schema.Boolean,
    Name: Schema.String,
  }),
  {
    ActiveDeployment: "activeDeployment",
    AutoDeploy: "autoDeploy",
    AvailableDeployments: "availableDeployments",
    Comments: "comments",
    DeploymentId: "deploymentId",
    DeploymentOrder: "deploymentOrder",
    DeployPassing: "deployPassing",
    Id: "id",
    Locked: "locked",
    Name: "name",
  },
)

export type Component = typeof ComponentSchema.Type

export const getAllDeployment = (
  component: Component,
): ReadonlyArray<Deployment> => {
  const availableDeployments = Option.getOrElse(
    component.availableDeployments,
    () => [],
  )

  return Option.match(component.activeDeployment, {
    onNone: () => availableDeployments,
    onSome: (activeDeployment) => [
      ...[activeDeployment],
      ...availableDeployments,
    ],
  })
}
