import type * as React from "react"
import type { FormikProps } from "formik"

type GenericRecord = Record<string, unknown>

type FieldStyleProps = {
  FormHelperTextProps: GenericRecord
  inputProps: GenericRecord
  hasError: boolean
  helperText: string
}

export const computeFieldStyleProps = (
  error: string | undefined,
  touched: boolean,
  helperText: string,
): FieldStyleProps => ({
  FormHelperTextProps: {
    style: { color: error && touched && "red" },
  },
  inputProps: {
    style: { borderColor: error && touched && "red" },
  },
  hasError: error == null ? false : touched,
  helperText: (touched && error) || helperText,
})

export const renderSelectHelperTextV2 = (
  error: string | undefined,
  touched: boolean,
) =>
  touched &&
  error && {
    hasError: true,
    helperText: error,
  }

export const validateRequired = (value: string): string | undefined => {
  if (value.length === 0) return "Required"
  return undefined
}

export const validateAwsId = (id: string): string | undefined => {
  const onlyNumbersRegex = /^[0-9]+$/
  if (id.length === 12 && onlyNumbersRegex.test(id)) {
    return undefined
  }

  return "Must be of exact length 12 and consist of only digits"
}

export const validateEmail = (email: string) => {
  const regex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
  if (!regex.test(email)) {
    return "Must be a valid email address"
  }

  return undefined
}

export const parseAndSetField =
  (onChange: FormikProps<unknown>["handleChange"]) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const parsedEvent = {
      ...event,
      target: {
        ...event.target,
        value: JSON.parse(event.target.value),
        // Shouldn't HAVE to pass this, because we've spread event.target, wtf?
        name: event.target.name,
      },
    }

    onChange(parsedEvent)
  }
