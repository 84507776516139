import { UsersResponseSchema, type User } from "~/models/User"
import type { RemoteData } from "~/models/RemoteData"
import { Schema } from "@effect/schema"
import { useQuery } from "~/common/hooks/useQuery"

export const useOrganisationUsers = (
  orgId: string,
): {
  remoteData: RemoteData<{ Users: ReadonlyArray<User> }, unknown>
} => {
  const { remoteData } = useQuery({
    url: `/users/${orgId}`,
    parse: Schema.decodeUnknownEither(UsersResponseSchema),
  })

  return { remoteData }
}
