import classNames from "classnames"

const Spinner = () => (
  <div style={{ height: "4rem", width: "4rem" }}>
    <svg
      className="animate-spin text-slate-500"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <title>Loading</title>
      <path
        d="m1,12C1,5.92,5.92,1,12,1"
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </div>
)

type LoadingProps = {
  classname?: string
  message?: string
}

export const Loading = (props: LoadingProps) => {
  const classes = classNames(
    "w-full flex justify-center pt-12",
    props.classname,
  )

  const message = props.message ?? "Loading..."

  return (
    <div className={classes}>
      <div>
        <Spinner />
        <div>{message}</div>
      </div>
    </div>
  )
}

export const BusyFullWidth = () => <Loading message="" />
