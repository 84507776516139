import * as log from "~/common/utils/log"
import type React from "react"
import type { AppUser } from "~/models/AppUser"
import type { Context } from "~/models/Context"
import type { Organisation } from "~/models/Organisation"
import type { User } from "~/models/User"
import { Can, Role } from "~/common/ui/Can"
import { ClickToCopy } from "~/common/ui/ClickToCopy"
import { ComponentSendVerifyEmail } from "./CustomerUserList/ComponentSendVerifyEmail"
import { Loading } from "~/common/ui/Loading"
import { NoResults } from "~/common/ui/NoResults"
import { Option } from "effect"
import { useOrganisationUsers } from "~/common/hooks/useOrganisationUsers"

const headers = [
  { title: "Name" },
  { title: "Email" },
  { title: "Federated" },
  { title: "MFA" },
  { title: "Enabled" },
  { title: "Email Verified" },
]

type Props = {
  context: Context
  organisation: Organisation
}

const getButtonTextAndDisabled = (args: {
  emailVerified: boolean
  user: User
  text: string
  isDisabled: boolean
}) => {
  const { emailVerified, user, text, isDisabled } = args

  if (emailVerified || !user.Enabled || user.Federated) {
    return {
      text: emailVerified ? "Verified" : "Not Verified",
      isDisabled: true,
    }
  }

  return { text, isDisabled }
}

const renderButton = (organisationId: string, user: User, disabled = false) => {
  const emailVerified = Option.getOrElse(user.EmailVerified, () => false)

  const { text, isDisabled } = getButtonTextAndDisabled({
    emailVerified,
    user,
    text: "Re-send",
    isDisabled: disabled,
  })

  return (
    <ComponentSendVerifyEmail
      userId={user.Id}
      organisationId={organisationId}
      disabled={isDisabled}
      text={text}
    />
  )
}

type Cell = {
  key: string
  ele: string | React.JSX.Element
}

const getTableRows = (
  currentUser: AppUser,
  organisationId: string,
  users: ReadonlyArray<User>,
): Array<{ key: string; cells: Array<Cell> }> => {
  return users.map((user) => ({
    key: user.Id,
    cells: [
      {
        key: "user",
        ele: (
          <>
            {user.FirstName} {user.LastName} <br />
            {user.Id} <ClickToCopy text={user.Id} />
          </>
        ),
      },

      {
        key: "email",
        ele: (
          <>
            {user.Email} <ClickToCopy text={user.Email} />
          </>
        ),
      },

      // TODO: "non-federated" or "user"?
      { key: "federated", ele: user.Federated ? "Federated" : "User" },
      { key: "mfa", ele: user.MFA ? "Enabled" : "Disabled" },
      { key: "enabled", ele: user.Enabled ? "Enabled" : "Disabled" },

      {
        key: "actions",
        ele: (
          <Can
            key={user.Id}
            currentUser={currentUser}
            requiredRole={Role.customersuccess}
            yes={renderButton(organisationId, user)}
            no={renderButton(organisationId, user, true)}
          />
        ),
      },
    ],
  }))
}

export const CustomerUserList = (props: Props) => {
  const { organisation } = props
  const organisationId = organisation.Id

  const { remoteData } = useOrganisationUsers(organisationId)

  if (organisation.Status === "SUSPENDED") {
    return <NoResults title="Organisation is suspended" />
  }

  const isLoading =
    remoteData._tag === "Loading" || remoteData._tag === "NotAsked"

  if (isLoading) {
    return <Loading classname="pb-12" />
  }

  if (remoteData._tag === "Failure") {
    log.error(remoteData.error)
    return <NoResults title="Error fetching Users" />
  }

  const { Users: users } = remoteData.data

  if (users.length === 0) {
    return <NoResults title="No users found" />
  }

  const rows = getTableRows(props.context.currentUser, organisationId, users)

  return (
    <table className="DataTable">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header.title}>{header.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.key}>
            {row.cells.map((cell: Cell) => (
              <td key={cell.key}>{cell.ele}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
