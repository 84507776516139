import { Schema } from "@effect/schema"

export const WorkloadCatalogueSchema = Schema.Struct({
  CatalogueVersionId: Schema.String,
  CreatedBy: Schema.String,
  CreatedTS: Schema.String,
  Description: Schema.String,
  Id: Schema.String,
  ModifiedTS: Schema.String,
  Name: Schema.String,
  OrganisationId: Schema.String,
  Public: Schema.Boolean,
  Status: Schema.String,
  UserTaskId: Schema.String,
})

export type WorkloadCatalogue = typeof WorkloadCatalogueSchema.Type

export const WorkloadCataloguesResponseSchema = Schema.Struct({
  WorkloadCatalogue: Schema.Array(WorkloadCatalogueSchema),
})
