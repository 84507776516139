import * as log from "~/common/utils/log"
import * as navigation from "~/common/utils/navigation"
import * as routes from "~/routes"
import type { Context } from "~/models/Context"
import { PageIsLoading, PageNotFound, PageFailure } from "~/common/ui/Page"
import { useCustomerOrgs } from "~/common/hooks/useCustomerOrgs"

type Props = {
  context: Context
  customerId: string
}

/*
This component loads the organisations for a customer
Finds the default org and redirects to it
*/
export const PageCustomerShow = (props: Props) => {
  const { customerId } = props

  const { remoteData: remoteDataOrgs } = useCustomerOrgs({
    customerId,
  })

  const isLoadingOrgs =
    remoteDataOrgs._tag === "Loading" || remoteDataOrgs._tag === "NotAsked"

  if (isLoadingOrgs) {
    return <PageIsLoading context={props.context} title="Customer" />
  }

  if (remoteDataOrgs._tag === "Failure") {
    log.error(remoteDataOrgs.error)
    return (
      <PageFailure
        context={props.context}
        title="Customer"
        message="Error fetching Organizations"
      />
    )
  }

  const { Organisations: organisations } = remoteDataOrgs.data

  const defaultOrg = organisations.find((o) => o.Name === "default")

  if (defaultOrg) {
    const nextRoute = routes.routeOrganisation(customerId, defaultOrg.Id)
    navigation.navigateToRoute(nextRoute)
    return null
  }

  // If there is no default org, we cannot render anything
  return (
    <PageNotFound
      context={props.context}
      title="Customer"
      message="No default organisation found"
    />
  )
}
