import {
  ServiceConfigurationsResponseSchema,
  type ServiceConfiguration,
} from "~/models/ServiceConfiguration"
import type { RemoteData } from "~/models/RemoteData"
import { Schema } from "@effect/schema"
import { useQuery } from "~/common/hooks/useQuery"

export const useOrganisationServiceConfigurations = (
  orgId: string,
): {
  remoteData: RemoteData<
    { ServiceConfigurations: ReadonlyArray<ServiceConfiguration> },
    unknown
  >
} => {
  const { remoteData } = useQuery({
    url: `/organisations/${orgId}/configuration`,
    parse: Schema.decodeUnknownEither(ServiceConfigurationsResponseSchema),
  })

  return { remoteData }
}
