import { DateTime, Duration, Option } from "effect"

export const add = (
  date: DateTime.DateTime,
  duration: Partial<DateTime.DateTime.PartsForMath>,
): DateTime.DateTime => {
  return DateTime.add(duration)(date)
}

export const formatDate = (date: DateTime.DateTime): string => {
  const options = {
    weekday: "short" as const,
    year: "numeric" as const,
    month: "short" as const,
    day: "2-digit" as const,
    hour: "2-digit" as const,
    minute: "2-digit" as const,
    second: "2-digit" as const,
  }

  return DateTime.format(date, options)
}

export const formatDistance = (
  date1: DateTime.DateTime,
  date2: DateTime.DateTime,
) => {
  const distance = DateTime.distanceDuration(date1, date2)
  return Duration.format(distance)
}

export const isAfter = (
  date: DateTime.DateTime,
  dateToCompare: DateTime.DateTime,
): boolean => {
  return DateTime.greaterThan(dateToCompare)(date)
}

export const now = (): DateTime.DateTime => {
  return DateTime.unsafeNow()
}

export const parseISO = (date: string): Option.Option<DateTime.DateTime> => {
  return DateTime.make(date)
}

export const parseISOOrNull = (date: string): DateTime.DateTime | null => {
  return Option.getOrNull(parseISO(date))
}
