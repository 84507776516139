import { pipe, Effect, Config, Logger, Layer, LogLevel } from "effect"

// console.log is disallowed.
// It is fine to use console.log during dev, but must be removed before committing.
// If we want to keep the message in prod, use .info instead,
// as the intention is explict.
//

// The default Effect logger is quite noisy, we want something minimal
const customLogger = Logger.make((args) => {
  const { logLevel, message } = args
  switch (logLevel) {
    case LogLevel.Info:
      return globalThis.console.info(message)
    case LogLevel.Error:
      return globalThis.console.error(message)
    case LogLevel.Warning:
      return globalThis.console.warn(message)
    case LogLevel.Debug:
      return globalThis.console.debug(message)
    default:
      return globalThis.console.info(message)
  }
})

const customLoggerLayer = Logger.replace(Logger.defaultLogger, customLogger)

const logLevelLayer = Config.logLevel("LOG_LEVEL").pipe(
  Config.withDefault(LogLevel.Info),
  Effect.andThen((level) => Logger.minimumLogLevel(level)),
  Layer.unwrapEffect,
)

const run = (program: Effect.Effect<void, never, never>) =>
  pipe(
    program,
    Effect.provide(logLevelLayer),
    Effect.provide(customLoggerLayer),
    Effect.runPromise,
  )

// Printed by default
// Basic allowed level, log interesting information
export const info = (...args: Array<unknown>) =>
  pipe(Effect.logInfo(...args), run)

// Printed by default
export const warn = (...args: Array<unknown>) =>
  pipe(Effect.logWarning(...args), run)

// Printed by default
export const error = (...args: Array<unknown>) =>
  pipe(Effect.logError(...args), run)

// Not printed by default
// Use this to debug difficult flows
export const debug = (...args: Array<unknown>) =>
  pipe(Effect.logDebug(...args), run)
