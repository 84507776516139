import classNames from "classnames"
import type React from "react"

type ChipProps = React.PropsWithChildren<{
  className?: string | undefined
}>

export const Chip = (props: ChipProps) => {
  const classes = classNames(
    "t-chip px-2 py-2 rounded-full text-center inline-flex items-center min-h-8 text-xs",
    { "bg-gray-400": !props.className?.includes("bg-") },
    props.className,
  )

  return <div className={classes}>{props.children}</div>
}
