import { v4 } from "uuid"
import { useCallback, useMemo, useState } from "react"
import {
  ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/24/solid"

type Props = {
  text: string
}

const style = {
  width: "16px",
  height: "16px",
}

const Copied = () => (
  <ClipboardDocumentCheckIcon className="fill-slate-300" style={style} />
)

const NotCopied = () => (
  <ClipboardDocumentIcon className="fill-slate-500" style={style} />
)

const performCopy = (elementID: string) => {
  let elem = document.getElementById(elementID)

  if (elem && elem instanceof HTMLInputElement) {
    elem.select()
    document.execCommand("copy")
  }
}

export const ClickToCopy = (props: Props) => {
  const id = useMemo(() => v4(), [])

  const [copied, setCopied] = useState(false)

  const title = copied ? "Copied!" : "Click to copy"

  const copy = useCallback(() => {
    setCopied(true)
    performCopy(id)
  }, [id])

  return (
    <span className="inline-block">
      <input
        id={id}
        value={props.text}
        readOnly
        className="focus:outline-none w-0 h-0"
      />

      <button
        className="cursor-copy"
        type="button"
        onClick={copy}
        title={title}
      >
        {copied && <Copied />}
        {!copied && <NotCopied />}
      </button>
    </span>
  )
}
