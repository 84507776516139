import qs from "qs"
import { pipe, Either, Record as ERecord } from "effect"

export type Value = string | null | Array<string>

export type Query = Record<string, Value>

type RawParsedQuery = { [key: string]: unknown }

const configParse: qs.IParseBaseOptions = { ignoreQueryPrefix: true }

const configStringify: qs.IStringifyBaseOptions = {
  encodeValuesOnly: true,
  arrayFormat: "brackets",
}

const mustBeOne = (
  value: string | Array<string>,
): Either.Either<string, string> => {
  if (Array.isArray(value)) {
    return Either.left("Expected one but found array")
  }
  return Either.right(value)
}

const mustBeMany = (
  value: string | Array<string>,
): Either.Either<Array<string>, string> => {
  if (Array.isArray(value)) {
    return Either.right(value)
  }
  return Either.left("Expected an array but found one")
}

const parseQueryValueToValue = (value: unknown): Value => {
  if (Array.isArray(value)) {
    return value.filter((x) => x !== null)
  }
  if (value === null) {
    return value
  }
  if (typeof value === "string") {
    return value
  }

  return null
}

export const parsedQuerytoQuery = (query: RawParsedQuery): Query => {
  return ERecord.map(query, parseQueryValueToValue)
}

const qsParse =
  (config: qs.IParseBaseOptions) =>
  (queryString: string): RawParsedQuery => {
    return qs.parse(queryString, config)
  }

const decode = (queryString: string): string => {
  return decodeURIComponent(queryString)
}

export const parse = (queryString: string): Query => {
  return pipe(queryString, decode, qsParse(configParse), parsedQuerytoQuery)
}

export const stringify = (query: Query): string => {
  return qs.stringify(query, configStringify)
}

export const getParam =
  (name: string) =>
  (query: Query): Either.Either<Array<string> | string, string> => {
    return pipe(
      query,
      (query) => query[name],
      Either.fromNullable(() => `Param ${name} not found`),
    )
  }

export const getStringParam =
  (name: string) =>
  (query: Query): Either.Either<string, string> => {
    return pipe(query, getParam(name), Either.andThen(mustBeOne))
  }

export const getStringArrayParam =
  (name: string) =>
  (query: Query): Either.Either<Array<string>, string> => {
    return pipe(query, getParam(name), Either.andThen(mustBeMany))
  }

export const setParam =
  (name: string, param: string | Array<string>) => (query: Query) => {
    return {
      ...query,
      [name]: param,
    }
  }

export const setParamOrRemove =
  (name: string, param: string | Array<string>) => (query: Query) => {
    const isEmptyString = param === ""
    const isEmptyArray = Array.isArray(param) && param.length === 0

    if (isEmptyString || isEmptyArray) {
      return removeParam(name)(query)
    }

    return setParam(name, param)(query)
  }

export const removeParam = (name: string) => (query: Query) => {
  return ERecord.remove(query, name)
}

export const merge = (query: Query) => (queryOld: Query) => {
  return {
    ...queryOld,
    ...query,
  }
}
