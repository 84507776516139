import * as routes from "~/routes"
import classNames from "classnames"
import { Link } from "~/common/ui/Link"

import type { NavLinkName } from "./NavLinks"
import {
  HomeIcon,
  AdjustmentsVerticalIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid"
import { Equal } from "effect"

const NameToIcon = {
  customers: HomeIcon,
  components: AdjustmentsVerticalIcon,
  provision: PlusCircleIcon,
}

interface NavIconProps {
  title: string
  name: NavLinkName
  compact: boolean
}

const NavIcon = (props: NavIconProps) => {
  const { compact, name } = props

  const Icon = NameToIcon[name]

  return (
    <div className="t-nav-icon flex flex-col items-center py-4">
      {!compact && (
        <div className="pb-2">
          <Icon style={{ height: "1rem" }} />
        </div>
      )}
      <div className="">{props.title}</div>
    </div>
  )
}

type Props = {
  className?: string
  compact: boolean
  currentAppLocation: routes.AppLocation
  name: NavLinkName
  route: routes.AppRoute
  title?: string
}

export const NavItem = (props: Props) => {
  const isActive = Equal.equals(props.currentAppLocation.route, props.route)

  const classes = classNames(
    "t-nav-item text-white opacity-50",
    {
      "opacity-100": true,
      "border-b": isActive,
    },
    props.className,
  )

  const href = routes.generate(props.route)

  return (
    <Link href={href} className={classes}>
      <NavIcon
        name={props.name}
        title={props.title ?? ""}
        compact={props.compact}
      />
    </Link>
  )
}
