import * as log from "~/common/utils/log"
import * as routes from "~/routes"
import React from "react"
import type { Context } from "~/models/Context"
import { Link } from "~/common/ui/Link"
import { NavAccountMenu } from "./Navigation/NavAccountMenu"
import { NavItem } from "./Navigation/NavItem"
import { NavLinks } from "./Navigation/NavLinks"
import { NavLogo } from "./Navigation/NavLogo"
import { Select, SelectItem } from "~/common/ui/Select"
import { STAGES, currentStageId } from "~/common/utils/stages"
import { stripFirstLetters } from "~/common/utils/strings"

type Props = {
  context: Context
  pageTitle: string
  pageActions?: React.ReactNode
}

const versentEmailEnding = "@versent.com.au"
const oktaEmailEnding = "@stax.io"

const initials = (username: string) => {
  return stripFirstLetters(
    username.replace(versentEmailEnding, "").replace(oktaEmailEnding, ""),
  )
}

export const Navigation = (props: Props) => {
  const { context } = props
  const { currentUser } = context

  const [compact, setCompact] = React.useState(false)

  const currentStage = currentStageId()

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || 0
    if (scrollTop > 0 && compact === false) {
      setCompact(true)
    } else if (scrollTop === 0 && compact === true) {
      setCompact(false)
    }
  }

  return (
    <div className="t-nav-container sticky top-0 z-10">
      <div className="bg-slate-800 flex justify-center">
        <div
          className="flex justify-between items-center"
          style={{ width: "var(--app-width)" }}
        >
          <div className="flex">
            <NavLogo compact={compact} />
            <nav className="t-nav-item-list flex space-x-4 ml-8">
              {NavLinks.map((navLink) => (
                <NavItem
                  compact={compact}
                  currentAppLocation={props.context.currentAppLocation}
                  key={navLink.name}
                  name={navLink.name}
                  route={navLink.route}
                  title={navLink.title}
                />
              ))}
            </nav>
          </div>

          <div className="flex items-center space-x-4">
            <Select
              value={currentStage}
              className="text-white border-slate-500"
              onValueChange={(value: string) => {
                const stage = STAGES.find((stage) => stage.stage === value)

                if (!stage) {
                  log.error("oh no") // should never happen
                  return
                }

                if (stage.stage === currentStage) return // no worries, stay put

                window.location.assign(stage.link)
              }}
            >
              {STAGES.map(({ stage }) => (
                <SelectItem key={stage} value={stage}>
                  {stage}
                </SelectItem>
              ))}
            </Select>

            <Link
              href={routes.generate(routes.routeAuthSignOut())}
              className="text-white cursor-pointer hover:underline ml-3"
            >
              Sign out
            </Link>

            <NavAccountMenu
              name={currentUser.name}
              region={window.AWS_CONFIG.JumaAuth.region}
              userId={currentUser.id}
              roles={currentUser.idamRoles}
              initials={initials(currentUser.name)}
            />
          </div>
        </div>
      </div>
      <div className="bg-white flex justify-center border-b border-b-slate-200">
        <div
          className="p-4 flex items-center justify-between"
          style={{ width: "var(--app-width)" }}
        >
          {props.pageTitle}
          {props.pageActions}
        </div>
      </div>
    </div>
  )
}
