import { AccountsResponseSchema, type Account } from "~/models/Account"
import type { RemoteData } from "~/models/RemoteData"
import { Schema } from "@effect/schema"
import { useQuery } from "~/common/hooks/useQuery"

export type Type = "USED" | "UNUSED"

export const useAccountBillingRoots = (
  type: Type,
): {
  remoteData: RemoteData<{ Accounts: ReadonlyArray<Account> }, unknown>
} => {
  const { remoteData } = useQuery({
    url: `/accounts?billing=${type}`,
    parse: Schema.decodeUnknownEither(AccountsResponseSchema),
  })

  return { remoteData }
}
