import { Schema } from "@effect/schema"

export const WorkloadSchema = Schema.Struct({
  AccountId: Schema.String,
  CatalogueId: Schema.String,
  CreatedBy: Schema.String,
  CreatedTS: Schema.String,
  FactoryVersion: Schema.String,
  Id: Schema.String,
  ModifiedTS: Schema.String,
  Name: Schema.String,
  OrganisationId: Schema.String,
  Region: Schema.String,
  Status: Schema.String,
  UserTaskId: Schema.String,
})

export type Workload = typeof WorkloadSchema.Type

export const WorkloadsResponseSchema = Schema.Struct({
  Workloads: Schema.Array(WorkloadSchema),
})
