import className from "classnames"
import { Link } from "~/common/ui/Link"
import { StaxLogo } from "~/common/ui/StaxLogo"

type Props = {
  compact: boolean
}

export const NavLogo = (props: Props) => {
  const { compact } = props

  const iconClasses = className({
    "fill-white": true,
    "w-8": compact,
    "w-10": !compact,
  })

  return (
    <Link
      href="/"
      className="flex justify-center items-center w-10"
      title="Juma"
    >
      <StaxLogo className={iconClasses} />
    </Link>
  )
}
