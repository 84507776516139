import * as routes from "~/routes"
import history from "history/browser"

export const navigate = (url: string) => {
  history.push(url)
}

export const navigateToRoute = (route: routes.AppRoute) => {
  const url = routes.generate(route)
  navigate(url)
}
