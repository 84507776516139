import type React from "react"
import { useRef, useEffect } from "react"

type Empty = Record<never, never>

type ModalProps = React.PropsWithChildren<{
  isOpen: boolean
  onClose: () => void
  "aria-labelledby"?: string
  "aria-describedby"?: string
}>

export const Modal = (props: ModalProps) => {
  const dialogRef = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    if (dialogRef.current?.open && !props.isOpen) {
      dialogRef.current?.close()
      props.onClose()
    } else if (!dialogRef.current?.open && props.isOpen) {
      dialogRef.current?.showModal()
    }
  }, [props.isOpen, props.onClose])

  const modalClasses =
    "t-modal m-4 p-4 w-2/5 min-w-[40%] max-w-[40%] rounded-lg bg-white shadow-sm"

  return (
    <dialog
      aria-labelledby={props["aria-labelledby"]}
      aria-describedby={props["aria-describedby"]}
      className={modalClasses}
      ref={dialogRef}
      style={{
        margin: 0,
        transform: "translateX(-50%) translateY(-50%)",
        left: "50%",
        top: "50%",
      }}
    >
      {props.children}
    </dialog>
  )
}

type ModalTitleProps = React.PropsWithChildren<{
  id: string
}>

export const ModalTitle = (props: ModalTitleProps) => {
  return (
    <header id={props.id} className="text-lg pb-3">
      {props.children}
    </header>
  )
}

type ModalContentProps = React.PropsWithChildren<Empty>

export const ModalContent = (props: ModalContentProps) => {
  return <div className="">{props.children}</div>
}

type ModalFooterProps = React.PropsWithChildren<Empty>

export const ModalFooter = (props: ModalFooterProps) => {
  return (
    <footer className="flex justify-end space-x-2 pt-5">
      {props.children}
    </footer>
  )
}
