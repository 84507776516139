import { Schema } from "@effect/schema"

const OrganisationAttachmentSchema = Schema.Record({
  key: Schema.String,
  value: Schema.Any,
})

export const PolicySchema = Schema.Struct({
  AwsId: Schema.String,
  Content: Schema.String,
  Description: Schema.String,
  Id: Schema.String,
  ModifiedTS: Schema.String,
  Name: Schema.String,
  PolicyOwner: Schema.String,
  PolicyType: Schema.String,
  OrganisationAttachment: Schema.OptionFromNullishOr(
    OrganisationAttachmentSchema,
    undefined,
  ),
  Status: Schema.String,
})

export type Policy = typeof PolicySchema.Type

export const PoliciesResponseSchema = Schema.Struct({
  Policies: Schema.Array(PolicySchema),
})
