import * as log from "~/common/utils/log"
import { CustomerTable } from "./CustomerList/CustomerTable"
import { Array as EArray, Order } from "effect"
import { Loading } from "~/common/ui/Loading"
import { NoResults } from "~/common/ui/NoResults"
import { useCustomers } from "~/common/hooks/useCustomers"

const headers = ["Customer", "Support Level", "Type", "Status"]

export const CustomerList = () => {
  const { remoteData } = useCustomers()

  if (remoteData._tag === "NotAsked" || remoteData._tag === "Loading") {
    return <Loading classname="pb-12" />
  }

  if (remoteData._tag === "Failure") {
    log.error(remoteData.error)
    return <NoResults title="Error fetching Customers" />
  }

  const { data } = remoteData
  const { Customers: customers } = data

  if (customers.length < 1) {
    return <NoResults title="No Customers" />
  }

  const sortedCustomers = EArray.sortWith(
    customers,
    (c) => c.Name,
    Order.string,
  )

  return <CustomerTable customers={sortedCustomers} headers={headers} />
}
