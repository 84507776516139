import * as log from "~/common/utils/log"
import * as navigation from "~/common/utils/navigation"
import * as routes from "~/routes"
import { ButtonOutline } from "~/common/ui/Buttons"
import { ConfigureRegionsModal } from "./CustomerServiceList/ConfigureRegionsModal"
import { ConfigureSecurityHubModal } from "./CustomerServiceList/ConfigureSecurityHubModal"
import type { Context } from "~/models/Context"
import type { Customer } from "~/models/Customer"
import { DateText } from "~/common/ui/DateText"
import { Loading } from "~/common/ui/Loading"
import { NoResults } from "~/common/ui/NoResults"
import { Either, Option, Struct } from "effect"
import type { Organisation } from "~/models/Organisation"
import { Schema } from "@effect/schema"
import { Status } from "~/common/ui/Status"
import { useOrganisationServiceConfigurations } from "~/common/hooks/useOrganisationServiceConfigurations"
import {
  type ServiceConfiguration,
  type Configuration,
  ConfigurationSecurityHubSchema,
  type ConfigurationSecurityHub,
} from "~/models/ServiceConfiguration"

const headers = [
  { title: "Service" },
  { title: "Status" },
  { title: "Created" },
  { title: "Info" },
  { title: "Actions" },
]

type Props = {
  context: Context
  customer: Customer
  organisation: Organisation
}

const InfoSecurityHub = ({
  configuration,
}: {
  configuration: Configuration
}) => {
  const standardsResult = Schema.decodeUnknownEither(
    ConfigurationSecurityHubSchema,
  )(configuration)

  return Either.match(standardsResult, {
    onLeft: (_error) => "Invalid Configuration",
    onRight: (configuration) => (
      <InfoSecurityHubStandards configuration={configuration} />
    ),
  })
}

const InfoSecurityHubStandards = (props: {
  configuration: ConfigurationSecurityHub
}) => {
  const { configuration } = props
  const { Standards } = configuration
  const withoutRegions = Struct.omit(Standards, "AWS_REGIONS")

  return (
    <>
      <b>Standards: </b>
      <br />
      <ul>
        {Object.entries(withoutRegions).map(([standard, value]) => (
          <li key={standard} className="pb-1">
            <span>{standard}</span>
            <br />
            <span className="text-slate-500">{value ? "On" : "Off"}</span>
          </li>
        ))}
      </ul>
      <b>Regions: </b>
      <div>{configuration.Standards.AWS_REGIONS?.join(", ")}</div>
    </>
  )
}

const Info = ({ service }: { service: ServiceConfiguration }) => {
  const configuration = Option.getOrElse(service.Configuration, () => ({}))

  if (service.Service === "SECURITYHUB") {
    return <InfoSecurityHub configuration={configuration} />
  }

  return <>{JSON.stringify(configuration)}</>
}

type ActionsProps = {
  service: ServiceConfiguration
}

const Actions = (props: ActionsProps) => {
  if (props.service.Service === "SECURITYHUB") {
    return <SecurityHubActions service={props.service} />
  }

  if (props.service.Service === "REGIONS") {
    return <RegionsActions service={props.service} />
  }

  return null
}

export const CustomerServiceList = (props: Props) => {
  const { context, customer, organisation } = props

  const organisationId = organisation.Id

  const { remoteData } = useOrganisationServiceConfigurations(organisationId)

  const customerId = customer.Id

  if (organisation.Status === "SUSPENDED") {
    return <NoResults title="Organisation is suspended" />
  }

  const isLoading =
    remoteData._tag === "Loading" || remoteData._tag === "NotAsked"

  if (isLoading) {
    return <Loading classname="pb-12" />
  }

  if (remoteData._tag === "Failure") {
    log.error(remoteData.error)
    return <NoResults title="Error fetching Services" />
  }

  const { ServiceConfigurations: serviceConfigurations } = remoteData.data

  if (serviceConfigurations.length === 0) {
    return <NoResults title="No services found" />
  }

  const showSecurityHubModal = routes.isInCustomerConfigureSecurityHub(
    context.currentAppLocation,
  )

  const showConfigureRegionsModal = routes.isInCustomerConfigureRegion(
    context.currentAppLocation,
  )

  return (
    <>
      {showSecurityHubModal ? (
        <ConfigureSecurityHubModal
          organisationId={organisationId}
          customerId={customerId}
          orgAlias={organisation.Alias || ""}
        />
      ) : null}

      {showConfigureRegionsModal ? (
        <ConfigureRegionsModal
          organisationId={organisationId}
          customerId={customerId}
          orgAlias={organisation.Alias || ""}
        />
      ) : null}

      <table className="DataTable">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.title}>{header.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {serviceConfigurations.map((service) => (
            <tr key={service.Id}>
              <td>{service.Service}</td>
              <td>
                <Status status={service.Status} />
              </td>
              <td>
                <DateText dateString={service.CreatedTS} />
              </td>
              <td width={"20%"}>
                <Info service={service} />
              </td>
              <td>
                <Actions service={service} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

const SecurityHubActions = (props: {
  service: ServiceConfiguration
}) => {
  const configureSecurityHub = () => {
    const url = routes.urlCustomerConfigureSecurityHub()
    navigation.navigate(url)
  }

  if (props.service.Service !== "SECURITYHUB") return null

  if (props.service.Status === "ACTIVE") {
    return (
      <ButtonOutline onClick={configureSecurityHub} variant="Danger">
        Configure
      </ButtonOutline>
    )
  }

  return <div>Users must self-service to activate Security Hub</div>
}

const RegionsActions = (props: {
  service: ServiceConfiguration
}) => {
  const { service } = props

  const configureRegions = () => {
    const url = routes.urlCustomerConfigureRegion()
    navigation.navigate(url)
  }

  if (service.Service !== "REGIONS") return null

  return (
    <ButtonOutline onClick={configureRegions} variant="Danger">
      Configure
    </ButtonOutline>
  )
}
