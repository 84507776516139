import { Schema } from "@effect/schema"

const ConfigurationSchema = Schema.Record({
  key: Schema.String,
  value: Schema.Unknown,
})

export type Configuration = typeof ConfigurationSchema.Type

export const ServiceConfigurationSchema = Schema.Struct({
  Configuration: Schema.OptionFromNullishOr(ConfigurationSchema, undefined),
  CreatedTS: Schema.String,
  Id: Schema.String,
  Service: Schema.String,
  Status: Schema.String,
})

export type ServiceConfiguration = typeof ServiceConfigurationSchema.Type

export const ServiceConfigurationsResponseSchema = Schema.Struct({
  ServiceConfigurations: Schema.Array(ServiceConfigurationSchema),
})

export const ConfigurationSecurityHubSchema = Schema.Struct({
  Standards: Schema.Struct({
    AWS_FOUNDATIONAL_SECURITY_BEST_PRACTICES_1_0_0: Schema.Boolean,
    AWS_REGIONS: Schema.Array(Schema.String),
    CIS_AWS_FOUNDATIONS_BENCHMARK_1_2_0: Schema.Boolean,
    CIS_AWS_FOUNDATIONS_BENCHMARK_1_4_0: Schema.OptionFromNullishOr(
      Schema.Boolean,
      undefined,
    ),
    CIS_AWS_FOUNDATIONS_BENCHMARK_3_0_0: Schema.Boolean,
    NIST_SP_800_53_REVISION_5: Schema.OptionFromNullishOr(
      Schema.Boolean,
      undefined,
    ),
    PCI_DSS_3_2_1: Schema.Boolean,
  }),
})

export type ConfigurationSecurityHub =
  typeof ConfigurationSecurityHubSchema.Type
