import * as log from "~/common/utils/log"
import { Fragment } from "react"
import { DateText } from "~/common/ui/DateText"
import { Loading } from "~/common/ui/Loading"
import { NoResults } from "~/common/ui/NoResults"
import type { Organisation } from "~/models/Organisation"
import type { Policy } from "~/models/Policy"
import type { PolicyAttachment } from "~/models/PolicyAttachment"
import { Status } from "~/common/ui/Status"
import {
  useOrganisationPolicies,
  useOrganisationPolicieAttachements,
} from "~/common/hooks/useOrganisationPolicies"
import { Option } from "effect"

const headers = [
  { title: "Name" },
  { title: "Description" },
  { title: "Organisation Attached" },
  { title: "Policy Type" },
  { title: "Policy Owner" },
  { title: "Status" },
  { title: "Modified" },
]

type Props = {
  organisation: Organisation
}

const getPolicyAttachmentTarget = (attachement: PolicyAttachment): string => {
  const organisationalUnitId = Option.getOrNull(
    attachement.OrganisationalUnitId,
  )

  const accountId = Option.getOrNull(attachement.AccountId)

  return organisationalUnitId || accountId || ""
}

const OrganisationAttachment = ({ policy }: { policy: Policy }) => {
  const value = policy.OrganisationAttachment ?? {}
  const keys = Object.keys(value)

  if (keys.length === 0) {
    return <>FALSE</>
  }

  return <>TRUE</>
}

const PolicyAttachments = ({
  policy,
  policyAttachments,
}: {
  policy: Policy
  policyAttachments: ReadonlyArray<PolicyAttachment>
}) => {
  return (
    <>
      {policyAttachments
        .filter((attachment) => attachment.PolicyId === policy.Id)
        .map((attachment) => (
          <div
            key={attachment.Id}
            style={{ paddingTop: "5px", paddingBottom: "5px" }}
          >
            <span style={{ display: "block" }}>
              Type: {attachment.PolicyAttachmentType}
            </span>
            <span style={{ display: "block" }}>
              Target: {getPolicyAttachmentTarget(attachment)}
            </span>
            <span style={{ display: "block" }}>
              Status: {attachment.Status}
            </span>
          </div>
        ))}
    </>
  )
}

export const CustomerPolicyList = (props: Props) => {
  const { organisation } = props
  const organisationId = organisation.Id

  const { remoteData } = useOrganisationPolicies(organisationId)

  const { remoteData: remoteDataAttachments } =
    useOrganisationPolicieAttachements(organisationId)

  if (organisation.Status === "SUSPENDED") {
    return <NoResults title="Organisation is suspended" />
  }

  const isLoading =
    remoteData._tag === "Loading" || remoteData._tag === "NotAsked"

  const isLoadingAttachements =
    remoteDataAttachments._tag === "Loading" ||
    remoteDataAttachments._tag === "NotAsked"

  if (isLoading || isLoadingAttachements) {
    return <Loading classname="pb-12" />
  }

  if (remoteData._tag === "Failure") {
    log.error(remoteData.error)
    return <NoResults title="Error fetching Policies" />
  }

  if (remoteDataAttachments._tag === "Failure") {
    log.error(remoteDataAttachments.error)
    return <NoResults title="Error fetching Policy Attachments" />
  }

  const { Policies: policies } = remoteData.data
  const { PolicyAttachments: policyAttachments } = remoteDataAttachments.data

  if (policies.length === 0) {
    return <NoResults title="No policies found" />
  }

  return (
    <table className="DataTable">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header.title}>{header.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {policies.map((policy) => (
          <Fragment key={policy.Id}>
            <tr key={policy.Id} className="borderNone">
              <td colSpan={6}>
                <p className="font-bold">Id:</p>
                {policy.Id} ({policy.AwsId})
              </td>
            </tr>
            <tr key={policy.Id} className="borderNone">
              <td>{policy.Name}</td>
              <td>{policy.Description}</td>
              <td>
                <OrganisationAttachment policy={policy} />
              </td>
              <td>{policy.PolicyType}</td>
              <td>{policy.PolicyOwner}</td>
              <td>
                <Status status={policy.Status} />
              </td>
              <td>
                <DateText dateString={policy.ModifiedTS} />
              </td>
            </tr>
            <tr key={`${policy.Id}extra`}>
              <td colSpan={2} className="border-bottom-none">
                <p className="font-bold">Attachments:</p>

                <PolicyAttachments
                  policy={policy}
                  policyAttachments={policyAttachments}
                />
              </td>
              <td colSpan={5}>
                <p className="font-bold">Policy:</p>
                <textarea className="w-full">
                  {JSON.stringify(policy.Content)}
                </textarea>
              </td>
            </tr>
          </Fragment>
        ))}
      </tbody>
    </table>
  )
}
