import * as RadixSelect from "@radix-ui/react-select"
import classNames from "classnames"
import type React from "react"

const {
  Root,
  Trigger,
  Value,
  Arrow,
  Content,
  Icon,
  Viewport,
  ScrollUpButton,
  ScrollDownButton,
  SelectItem: RSelectItem,
} = RadixSelect

type Props = React.PropsWithChildren<{
  className?: string
  onValueChange: (val: string) => void
  value: string
}>

export const Select = (props: Props) => {
  const triggerClasses = classNames("px-4 h-10", props.className)

  return (
    <Root value={props.value} onValueChange={props.onValueChange}>
      <Trigger className={triggerClasses}>
        <Value>{props.value}</Value>
        <Icon className="ml-2" />
      </Trigger>

      <Content position="popper">
        <ScrollUpButton className="flex items-center justify-center text-gray-700 dark:text-gray-300" />
        <Viewport className="bg-white p-2 rounded-lg shadow-lg">
          {props.children}
        </Viewport>
        <ScrollDownButton className="flex items-center justify-center text-gray-700 dark:text-gray-300" />
        <Arrow />
      </Content>
    </Root>
  )
}

type PropsItem = React.PropsWithChildren<{ value: string }>

export const SelectItem = (props: PropsItem) => {
  return (
    <RSelectItem
      value={props.value}
      className={classNames(
        "relative flex items-center px-8 py-2 rounded-md text-sm text-gray-700 font-medium focus:bg-gray-100",
        "focus:outline-none select-none",
      )}
    >
      {props.children}
    </RSelectItem>
  )
}
