import * as Sentry from "@sentry/browser"
import ReactDOM from "react-dom/client"
import { Amplify } from "aws-amplify"
import { App } from "./App"
import { getAmplifyConfig, getConfig } from "~/common/utils/config"
import { Option } from "effect"

const element = document.getElementById("root")
const config = getConfig()
const amplifyConfig = getAmplifyConfig(config)

Amplify.configure(amplifyConfig)

Option.match(config.sentry, {
  onNone: () => undefined,
  onSome: (sentry) => Sentry.init(sentry),
})

if (element) {
  const root = ReactDOM.createRoot(element)
  root.render(<App config={config} />)
} else {
  throw new Error("Root element missing.")
}
