import "./app.css"
import * as RadixToast from "@radix-ui/react-toast"
import * as routes from "~/routes"
import history from "history/browser"
import type { AppConfig } from "./common/utils/config"
import { AuthWrapper } from "~/wrappers/AuthWrapper"
import type { Context } from "~/models/Context"
import { getJson } from "~/common/api/apiFetchers"
import { PageComponents } from "~/pages/PageComponents"
import { PageCustomerList } from "~/pages/PageCustomerList"
import { PageCustomerShow } from "~/pages/PageCustomerShow"
import { PageOrganisationShow } from "~/pages/PageOrganisationShow"
import { PageProvision } from "~/pages/PageProvision"
import { PageProvisionAddBillingRoot } from "~/pages/PageProvisionAddBillingRoot"
import { PageProvisionAddCustomer } from "~/pages/PageProvisionAddCustomer"
import { PageProvisionAddOrg } from "~/pages/PageProvisionAddOrg"
import { PageProvisionVerification } from "~/pages/PageProvisionVerification"
import { PageShowcase } from "~/pages/PageShowcase"
import { SWRConfig, type SWRConfiguration } from "swr"
import {
  useElmish,
  cmd,
  type Dispatch,
  type InitResult,
  type SubscriptionResult,
  type UpdateReturnType,
} from "react-elmish"

type Model = { currentAppLocation: routes.AppLocation }

type InitProps = { initialAppLocation: routes.AppLocation }

type Message = { name: "ChangedAppLocation"; appLocation: routes.AppLocation }

const init = (props: InitProps): InitResult<Model, Message> => {
  return [
    {
      currentAppLocation: props.initialAppLocation,
    },
  ]
}

const update = (
  model: Model,
  msg: Message,
  _props: InitProps,
): UpdateReturnType<Model, Message> => {
  switch (msg.name) {
    case "ChangedAppLocation": {
      return [{ ...model, currentAppLocation: msg.appLocation }]
    }
  }
}

const subscription = (_model: Model): SubscriptionResult<Message> => {
  const sub = (dispatch: Dispatch<Message>): void => {
    history.listen(({ location }) => {
      let nextAppLocation = routes.resolveLocation(location)
      return dispatch({
        name: "ChangedAppLocation",
        appLocation: nextAppLocation,
      })
    })
  }

  return [cmd.ofSub(sub)]
}

export const App = (props: { config: AppConfig }) => {
  const { config } = props
  const initialLocation = history.location
  const initialAppLocation = routes.resolveLocation(initialLocation)

  const [model, _dispatch] = useElmish<InitProps, Model, Message>({
    props: {
      initialAppLocation: initialAppLocation,
    },
    init,
    update,
    name: "App",
    subscription,
  })

  const swfConfig: SWRConfiguration = {
    fetcher: getJson,
  }

  return (
    <SWRConfig value={swfConfig}>
      <RadixToast.Provider>
        <AuthWrapper
          config={config}
          currentAppLocation={model.currentAppLocation}
        >
          {({ user }) => {
            const context: Context = {
              currentUser: user,
              currentAppLocation: model.currentAppLocation,
            }
            return (
              <>
                <Toast />
                <CurrentView context={context} />
              </>
            )
          }}
        </AuthWrapper>
      </RadixToast.Provider>
    </SWRConfig>
  )
}

const Toast = () => {
  return (
    <div
      className="t-toast-placeholder fixed right-5 w-80 z-100"
      role="alert"
      style={{ top: "4rem" }}
    >
      <RadixToast.Viewport id="appToasts" />
    </div>
  )
}

type CurrentViewProps = {
  context: Context
}

const CurrentView = (props: CurrentViewProps) => {
  const { context } = props
  const currentRoute = context.currentAppLocation.route

  if (currentRoute._tag === "RouteRoot") {
    return <PageCustomerList context={context} />
  }

  if (currentRoute._tag === "RouteInCustomer") {
    if (currentRoute.sub._tag === "RouteCustomerShow") {
      return (
        <PageCustomerShow
          context={context}
          customerId={currentRoute.customerId}
        />
      )
    }

    if (currentRoute.sub._tag === "RouteCustomerAddOrganisation") {
      return (
        <PageProvisionAddOrg
          context={context}
          customerId={currentRoute.customerId}
        />
      )
    }
  }

  if (currentRoute._tag === "RouteInOrganisation") {
    return (
      <PageOrganisationShow
        context={context}
        currentOrganisationRoute={currentRoute.sub}
        customerId={currentRoute.customerId}
        orgId={currentRoute.orgId}
      />
    )
  }

  if (
    currentRoute._tag === "RouteComponents" ||
    currentRoute._tag === "RouteComponent"
  ) {
    return <PageComponents context={context} />
  }

  if (currentRoute._tag === "RouteProvision") {
    return <PageProvision context={context} />
  }

  if (currentRoute._tag === "RouteProvisionAddOrganisation") {
    return <PageProvisionAddOrg context={context} />
  }

  if (currentRoute._tag === "RouteProvisionAddBillingRoot") {
    return <PageProvisionAddBillingRoot context={context} />
  }

  if (currentRoute._tag === "RouteProvisionAddCustomer") {
    return <PageProvisionAddCustomer context={context} />
  }

  if (currentRoute._tag === "RouteProvisionVerify") {
    return <PageProvisionVerification context={context} />
  }

  if (currentRoute._tag === "RouteDevShowcase") {
    return <PageShowcase />
  }

  return <PageCustomerList context={context} />
}
