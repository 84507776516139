import { Schema } from "@effect/schema"

export const PolicyAttachmentSchema = Schema.Struct({
  AccountId: Schema.OptionFromNullishOr(Schema.String, undefined),
  Id: Schema.String,
  OrganisationalUnitId: Schema.OptionFromNullishOr(Schema.String, undefined),
  PolicyAttachmentType: Schema.String,
  PolicyId: Schema.String,
  Status: Schema.String,
})

export type PolicyAttachment = typeof PolicyAttachmentSchema.Type

export const PolicyAttachementsResponseSchema = Schema.Struct({
  PolicyAttachments: Schema.Array(PolicyAttachmentSchema),
})
