import className from "classnames"
import { ButtonSolid } from "~/common/ui/Buttons"
import { Can, Role } from "~/common/ui/Can"
import { ComponentDiff } from "./ComponentDiff"
import { ComponentTime } from "./ComponentTime"
import { ComponentVersion } from "./ComponentVersion"
import type { Context } from "~/models/Context"
import type { Deployment, DeploymentStatus } from "~/models/Component"
import { Toast } from "~/common/ui/Toast"

const ButtonDeployContent = (props: {
  isCurrent: boolean
  status: DeploymentStatus
}) => {
  const { isCurrent, status } = props
  if (status === "PENDING") return "Loading..."
  if (status === "ERROR") return "Error :("
  if (status === "SUCCESS") return "Deployed :)"
  return isCurrent ? "Redeploy" : "Deploy"
}

const DeployToast = (props: { id: string; status: DeploymentStatus }) => {
  if (props.status === "SUCCESS") {
    return <Toast id={props.id} message="Deployed" variant="success" />
  }

  if (props.status === "ERROR") {
    return (
      <Toast id={props.id} message="Something went wrong" variant="error" />
    )
  }

  return null
}

type Props = {
  activeVersion: string | undefined
  context: Context
  deployment: Deployment
  deploymentStatuses: { [key: string]: DeploymentStatus }
  isCurrent: boolean
  isNew: boolean
  locked: boolean
  onDeployComponent: (deploymentId: string) => void
}

export const ComponentBuild = (props: Props) => {
  const {
    activeVersion,
    deployment,
    isCurrent,
    isNew,
    locked,
    deploymentStatuses,
  } = props

  const status = deploymentStatuses[deployment.id] || "IDLE"

  const handleDeploy = () => {
    if (deployment) {
      props.onDeployComponent(deployment.id)
    }
  }

  const rootClasses = className({
    "t-component-build py-4 px-4 border-b border-slate-200": true,
    "bg-slate-200": isCurrent,
  })

  return (
    <div className={rootClasses}>
      <div className="grid grid-cols-5">
        <div className="t-column-info col-span-3">
          <div className="">
            <ComponentTime
              className="text-slate-900 font-semibold"
              time={deployment.modifiedTS}
              isNew={isNew}
            />
            by {deployment.commitAuthor || "Unknown"}
          </div>
          <ComponentVersion type="GitHash" version={deployment.version} />
          {isNew && (
            <ComponentDiff
              activeVersion={activeVersion || undefined}
              targetVersion={deployment.version}
              repoName={deployment.pipelineSlug}
            />
          )}
          <p className="text-slate-400 pt-2">{deployment.changeLog}</p>
        </div>

        <div className="t-column-current">
          {isCurrent && <span>Current</span>}
          {isNew && <span className="text-green-600">New</span>}
        </div>

        <DeployToast id={deployment.id} status={status} />

        {!locked && (
          <div className="t-column-deploy">
            <Can
              currentUser={props.context.currentUser}
              requiredRole={Role.deployer}
              yes={
                <ButtonSolid
                  onClick={handleDeploy}
                  disabled={status === "PENDING"}
                >
                  <ButtonDeployContent isCurrent={isCurrent} status={status} />
                </ButtonSolid>
              }
              no={
                <ButtonSolid disabled>
                  <ButtonDeployContent isCurrent={isCurrent} status={status} />
                </ButtonSolid>
              }
            />
          </div>
        )}
      </div>
    </div>
  )
}
