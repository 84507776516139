import { post, put } from "~/common/api/apiFetchers"
import { amplifyJsonResponseToRemoteData } from "~/common/api/middleware"
import { Schema } from "@effect/schema"
import type { RemoteData } from "~/models/RemoteData"
import { pipe } from "effect"

export const configureRegions = async (
  orgId: string,
): Promise<RemoteData<string, string>> => {
  const schema = Schema.String
  const url = `/organisations/${orgId}/configuration/regions/configure`

  return pipe(put(url, {}), amplifyJsonResponseToRemoteData(schema))
}

export const configureSecurityHub = async (
  orgId: string,
): Promise<RemoteData<string, string>> => {
  const schema = Schema.String
  const url = `/organisations/${orgId}/configuration/security-hub/configure`

  return pipe(put(url, {}), amplifyJsonResponseToRemoteData(schema))
}

export const runOrgValidation = async (orgId: string) => {
  const url = "/saga/organisation/validation"
  const payload = { Id: orgId }

  return post(url, payload)
}

export const invokeSaga = (orgId: string) => {
  const url = "/saga/organisation"
  const payload = { Id: orgId }

  return post(url, payload)
}

export const runAllAccountsSaga = async (orgId: string) => {
  const url = "/saga/organisation/all-accounts"
  const payload = { OrganisationId: orgId }

  return post(url, payload)
}

export const rediscoverAllAccounts = async (orgId: string) => {
  const url = "/saga/accountdiscovery"
  const payload = { OrganisationId: orgId }

  return post(url, payload)
}

export type AccessRequest = {
  orgId: string
  accessDurationInMins: number
  reason: string
  requestor: string
  staxAccountId: string
}

export const requestAccountAccess = async (accessRequest: AccessRequest) => {
  const url = `/organisations/${accessRequest.orgId}/account-access`

  return post(url, accessRequest)
}

export const setConfig = async (
  orgId: string,
  key: string,
  valueBool: boolean,
  comment = "via admin UI",
) => {
  const value = valueBool ? "true" : "false"
  const url = `/organisations/${orgId}/idam/config/${key}`
  const payload = { value, comment }

  return put(url, payload)
}

// runApplyStaxPolicies
export const applyStaxPolicies = async (orgId: string) => {
  const url = `/organisation/${orgId}/policies/stax`

  const payload = {
    OrganisationId: orgId,
  }

  return put(url, payload)
}

export const syncPolicies = async (orgId: string) => {
  const url = `/organisation/${orgId}/policies/sync`

  const payload = {
    OrganisationId: orgId,
  }

  return put(url, payload)
}

export const syncOrganisationalUnits = async (orgId: string) => {
  const url = `/organisation/${orgId}/organisational-units/sync`
  const payload = { OrganisationId: orgId }

  return put(url, payload)
}
