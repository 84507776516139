import type { ReactNode } from "react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import { NoDataIcon } from "./NoDataIcon"

interface NoResultsProps {
  title: string
  subtitle?: string | ReactNode
  isError?: boolean
}

export const NoResults = (props: NoResultsProps) => {
  const { title, subtitle = null, isError = false } = props

  return (
    <div className="t-no-results flex justify-center py-4">
      <div className="flex flex-col items-center">
        {!isError && <NoDataIcon style={{ height: "8rem" }} />}
        {isError && (
          <ExclamationTriangleIcon
            className="fill-slate-300"
            style={{ height: "8rem" }}
          />
        )}
        <div className="text-lg text-slate-500">{title}</div>
      </div>

      <div className="text-slate-500">{subtitle}</div>
    </div>
  )
}
