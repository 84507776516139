import * as log from "~/common/utils/log"
import * as RemoteData from "~/models/RemoteData"
import * as routes from "~/routes"
import classNames from "classnames"
import type { Context } from "~/models/Context"
import { CustomerAccountList } from "~/widgets/CustomerAccountList"
import { CustomerAndOrgDetails } from "~/widgets/CustomerAndOrgDetails"
import { CustomerCatalogue } from "~/widgets/CustomerCatalogue"
import { CustomerOrganisationalUnitList } from "~/widgets/CustomerOrganisationalUnitList"
import { CustomerPolicyList } from "~/widgets/CustomerPolicyList"
import { CustomerServiceList } from "~/widgets/CustomerServiceList"
import { CustomerUserList } from "~/widgets/CustomerUserList"
import { CustomerWorkloads } from "~/widgets/CustomerWorkloads"
import { Link } from "~/common/ui/Link"
import { Option, pipe } from "effect"
import { Panel } from "~/common/ui/Panels"
import { useAccountBillingRoots } from "~/common/hooks/useAccountBillingRoots"
import { useCustomerOrgs } from "~/common/hooks/useCustomerOrgs"
import { useCustomers } from "~/common/hooks/useCustomers"
import { useOrgIdamConfig } from "~/common/hooks/useOrgIdamConfig"
import {
  Page,
  PageIsLoading,
  PageNotFound,
  PageFailure,
} from "~/common/ui/Page"

const tabs = [
  {
    label: "Accounts",
    subroute: routes.RouteOrganisationShow(),
    Component: CustomerAccountList,
  },
  {
    label: "Workloads",
    subroute: routes.RouteOrganisationWorkloads(),
    Component: CustomerWorkloads,
  },
  {
    label: "Catalogue",
    subroute: routes.RouteOrganisationCatalogue(),
    Component: CustomerCatalogue,
  },
  {
    label: "Users",
    subroute: routes.RouteOrganisationUsers(),
    Component: CustomerUserList,
  },
  {
    label: "Services",
    subroute: routes.RouteOrganisationServices(),
    Component: CustomerServiceList,
  },
  {
    label: "Policies",
    subroute: routes.RouteOrganisationPolicies(),
    Component: CustomerPolicyList,
  },
  {
    label: "Organisational Units",
    subroute: routes.RouteOrganisationOrganisationalUnits(),
    Component: CustomerOrganisationalUnitList,
  },
]

type Props = {
  context: Context
  currentOrganisationRoute: routes.OrganisationRoute
  customerId: string
  orgId: string
}

export const PageOrganisationShow = (props: Props) => {
  const { currentOrganisationRoute, customerId, orgId } = props

  // There is no endpoint to fetch a single customer
  const { remoteData: remoteDataCustomers } = useCustomers()

  const { remoteData: remoteDataOrgs } = useCustomerOrgs({
    customerId,
  })

  const { remoteData: remoteDataUnusedBillingRoots } =
    useAccountBillingRoots("UNUSED")

  const { remoteData: remoteDataConfig } = useOrgIdamConfig(orgId)

  const isLoadingCustomers =
    remoteDataCustomers._tag === "Loading" ||
    remoteDataCustomers._tag === "NotAsked"

  const isLoadingOrgs =
    remoteDataOrgs._tag === "Loading" || remoteDataOrgs._tag === "NotAsked"

  const isLoadingBillingRoots =
    remoteDataUnusedBillingRoots._tag === "Loading" ||
    remoteDataUnusedBillingRoots._tag === "NotAsked"

  const isLoadingConfig =
    remoteDataConfig._tag === "Loading" || remoteDataConfig._tag === "NotAsked"

  if (
    isLoadingCustomers ||
    isLoadingOrgs ||
    isLoadingBillingRoots ||
    isLoadingConfig
  ) {
    return <PageIsLoading context={props.context} title="Organisation" />
  }

  if (remoteDataCustomers._tag === "Failure") {
    log.error(remoteDataCustomers.error)
    return (
      <PageFailure
        context={props.context}
        message="Error fetching Customers"
        title="Organisation"
      />
    )
  }

  if (remoteDataOrgs._tag === "Failure") {
    log.error(remoteDataOrgs.error)
    return (
      <PageFailure
        context={props.context}
        message="Error fetching Organisations"
        title="Organisation"
      />
    )
  }

  if (remoteDataUnusedBillingRoots._tag === "Failure") {
    log.error(remoteDataUnusedBillingRoots.error)
    return (
      <PageFailure
        context={props.context}
        message="Error fetching Billing Roots"
        title="Organisation"
      />
    )
  }

  if (remoteDataConfig._tag === "Failure") {
    log.error(remoteDataConfig.error)
  }

  const { Accounts: unusedBillingRoots } = remoteDataUnusedBillingRoots.data

  const { Customers: customers } = remoteDataCustomers.data

  const { Organisations: customerOrganisations } = remoteDataOrgs.data

  // Some customers return 404 here
  // We have no way to check the status atm
  // Status needs to be added to the error
  const organisationIdamConfigs = pipe(
    remoteDataConfig,
    RemoteData.toMaybe,
    Option.getOrElse(() => []),
  )

  const unusedBillingRootsCount = unusedBillingRoots.length

  const maybeCustomer = customers.find((c) => c.Id === customerId)

  if (maybeCustomer == null) {
    return (
      <PageNotFound
        context={props.context}
        title="Organisation"
        message="Customer not found"
      />
    )
  }

  const maybeCurrentOrg = customerOrganisations.find((o) => o.Id === orgId)

  if (!maybeCurrentOrg) {
    return (
      <PageNotFound
        context={props.context}
        title="Organisation"
        message="Organisation not found"
      />
    )
  }

  const customer = maybeCustomer
  const organisation = maybeCurrentOrg

  return (
    <Page context={props.context} title={customer.Name}>
      <CustomerAndOrgDetails
        context={props.context}
        customer={customer}
        organisation={organisation}
        organisationIdamConfigs={organisationIdamConfigs}
        organisations={customerOrganisations}
        unusedBillingRootsCount={unusedBillingRootsCount}
      />

      <Panel>
        <div className="flex space-x-2 border-b border-slate-300">
          {tabs.map((tab) => {
            const isActive = currentOrganisationRoute._tag === tab.subroute._tag

            const classes = classNames({
              "p-3 cursor-pointer border-b-4": true,
              "border-b-white": !isActive,
              "border-b-sky-300": isActive,
            })

            const route = routes.RouteInOrganisation({
              customerId,
              orgId,
              sub: tab.subroute,
            })

            const href = routes.generate(route)

            return (
              <Link className={classes} key={tab.label} href={href}>
                {tab.label}
              </Link>
            )
          })}
        </div>

        {tabs.map((tab) => {
          const { Component, label } = tab
          if (currentOrganisationRoute._tag === tab.subroute._tag) {
            return (
              <Component
                context={props.context}
                customer={customer}
                key={label}
                organisation={organisation}
                organisationIdamConfigs={organisationIdamConfigs}
              />
            )
          }
          return null
        })}
      </Panel>
    </Page>
  )
}
