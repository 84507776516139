import * as RemoteData from "~/models/RemoteData"
import useSWR from "swr"
import type { ApiJsonResponse } from "~/common/api/apiFetchers"
import type { DocumentType } from "@aws-amplify/core/internals/utils"
import { pipe, type Either } from "effect"
import { queryToRemoteData } from "~/common/utils/http"

type Props<Data, Err> = {
  url: string
  shouldFetch?: boolean
  parse: (data: DocumentType) => Either.Either<Data, Err>
}

export const useQuery = <Data, Err>(props: Props<Data, Err>) => {
  const { url, parse, shouldFetch = true } = props

  const query = useSWR<ApiJsonResponse, Err>(shouldFetch ? url : null)

  const remoteData = pipe(
    query,
    queryToRemoteData,
    RemoteData.map(parse),
    RemoteData.unwrapEither,
  )

  return { query, remoteData }
}
