import type React from "react"
import { Chip } from "~/common/ui/Chip"

type Props = React.ComponentProps<typeof Chip> & {
  status?: StatusType
}

type StatusType =
  | string
  | "ACTIVE"
  | "CREATE_FAILED"
  | "INITIALIZING"
  | "UPDATE_AVAILABLE"
  | "SUSPENDED"
  | "PROSPECT"
  | "DELETED"
  | "DELETE_IN_PROGRESS"
  | "UPDATE_IN_PROGRESS"
  | "NEW"
  | "UPDATE_FAILED"
  | "DISABLED"
  | "INVITED"
  | "ACTION_REQUIRED"
  | "OPEN"
  | "RESOLVED"
  | "PENDING"
  | "FAILED"
  | "CLOSED"
  | "ONBOARDING"
  | "ERROR"
  | "LOCKED"
  | "DISCOVERED"
  | "CREATE_IN_PROGRESS"

const cyan = "bg-cyan-500"
const amber = "bg-amber-500"
const pink = "bg-pink-500"
const grey = "bg-gray-500"

const StatusToColor = new Map<StatusType, string>([
  ["ACTIVE", cyan],
  ["OPEN", cyan],
  ["UP", cyan],
  ["NEW", amber],
  ["PENDING", amber],
  ["INITIALIZING", amber],
  ["UPDATE_IN_PROGRESS", amber],
  ["ONBOARDING", amber],
  ["UPDATE_AVAILABLE", amber],
  ["CREATE_IN_PROGRESS", amber],
  ["LOCKED", amber],
  ["ACTION_REQUIRED", pink],
  ["DELETE_IN_PROGRESS", pink],
  ["SUSPENDED", pink],
  ["CREATE_FAILED", pink],
  ["UPDATE_FAILED", pink],
  ["ERROR", pink],
  ["FAILED", pink],
  ["DOWN", pink],
  ["DELETED", grey],
  ["DISABLED", grey],
  ["INVITED", grey],
  ["PROSPECT", grey],
  ["RESOLVED", grey],
  ["UNKNOWN", grey],
  ["CLOSED", grey],
  ["DISCOVERED", grey],
])

export const Status = (props: Props) => {
  const { children, status } = props
  const text = children || status

  const bgColorClass = status && StatusToColor.get(status)

  return (
    <Chip className={bgColorClass}>
      <div className="text-white font-semibold">{text}</div>
    </Chip>
  )
}
