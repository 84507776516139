import * as amplifyApi from "@aws-amplify/api"
import invariant from "tiny-invariant"
import type { DocumentType } from "@aws-amplify/core/internals/utils"

const API_NAME = "adminapi"

export type AmplifyResponsePromise = ReturnType<
  typeof amplifyApi.get
>["response"]

export type AmplifyResponse = Awaited<AmplifyResponsePromise>

export type ApiJsonResponse = {
  statusCode: number
  json: DocumentType
}

export const get = (path: string): Promise<AmplifyResponse> => {
  invariant(typeof path === "string", "path must be a string")

  return amplifyApi.get({
    apiName: API_NAME,
    path,
  }).response
}

export const getJson = (path: string): Promise<ApiJsonResponse> => {
  return get(path).then(toApiJsonResponse)
}

export const post = (
  path: string,
  body: DocumentType,
): Promise<AmplifyResponse> => {
  invariant(typeof path === "string", "path must be a string")

  return amplifyApi.post({
    apiName: API_NAME,
    path,
    options: { body },
  }).response
}

export const put = (
  path: string,
  body: DocumentType,
): Promise<AmplifyResponse> => {
  invariant(typeof path === "string", "path must be a string")

  return amplifyApi.put({
    apiName: API_NAME,
    path,
    options: { body },
  }).response
}

export type AmplifyDeleteResponsePromise = ReturnType<
  typeof amplifyApi.del
>["response"]

export type AmplifyDeleteResponse = Awaited<AmplifyDeleteResponsePromise>

export const del = (path: string): Promise<AmplifyDeleteResponse> => {
  invariant(typeof path === "string", "path must be a string")

  return amplifyApi.del({
    apiName: API_NAME,
    path,
  }).response
}

const toApiJsonResponse = async (
  response: AmplifyResponse,
): Promise<ApiJsonResponse> => {
  return {
    statusCode: response.statusCode,
    json: await response.body.json(),
  }
}

export const isSuccessCode = (response: AmplifyResponse): boolean =>
  String(response.statusCode).startsWith("2")
