import generateUrls from "universal-router/generateUrls"
import UniversalRouter, {
  type Route,
  type RouteContext,
} from "universal-router/sync"
import { Data, Either, pipe } from "effect"
import * as queryString from "~/common/utils/queryString"
import { Schema } from "@effect/schema"
import type { Location } from "history"

type Empty = Record<never, never>

export type AppLocation = {
  route: AppRoute
  query: queryString.Query
}

export type AppRoute = Data.TaggedEnum<{
  RouteNotFound: Empty
  RouteRoot: Empty
  RouteDevShowcase: Empty
  RouteInAuth: { readonly sub: RouteAuth }
  RouteComponents: Empty
  RouteComponent: { readonly name: string }
  RouteCustomers: Empty
  RouteInCustomer: {
    readonly customerId: string
    readonly sub: CustomerRoute
  }
  RouteInOrganisation: {
    readonly customerId: string
    readonly orgId: string
    readonly sub: OrganisationRoute
  }
  RouteProvision: Empty
  RouteProvisionAddBillingRoot: Empty
  RouteProvisionAddCustomer: Empty
  RouteProvisionAddOrganisation: Empty
  RouteProvisionVerify: Empty
}>

export const {
  RouteRoot,
  RouteNotFound,
  RouteDevShowcase,
  RouteInAuth,
  RouteComponents,
  RouteComponent,
  RouteCustomers,
  RouteInCustomer,
  RouteInOrganisation,
  RouteProvision,
  RouteProvisionAddBillingRoot,
  RouteProvisionAddCustomer,
  RouteProvisionAddOrganisation,
  RouteProvisionVerify,
} = Data.taggedEnum<AppRoute>()

type RouteAuth = Data.TaggedEnum<{
  RouteAuthLogin: Empty
  RouteAuthCallback: Empty
  RouteAuthSignOut: Empty
}>

export const { RouteAuthLogin, RouteAuthCallback, RouteAuthSignOut } =
  Data.taggedEnum<RouteAuth>()

type CustomerRoute = Data.TaggedEnum<{
  RouteCustomerShow: Empty
  RouteCustomerAddOrganisation: Empty
  RouteInOrganisation: {
    readonly orgId: string
    readonly sub: OrganisationRoute
  }
}>

export const { RouteCustomerShow, RouteCustomerAddOrganisation } =
  Data.taggedEnum<CustomerRoute>()

export type OrganisationRoute = Data.TaggedEnum<{
  RouteOrganisationShow: Empty
  RouteOrganisationWorkloads: Empty
  RouteOrganisationCatalogue: Empty
  RouteOrganisationUsers: Empty
  RouteOrganisationServices: Empty
  RouteOrganisationPolicies: Empty
  RouteOrganisationOrganisationalUnits: Empty
}>

export const {
  RouteOrganisationShow,
  RouteOrganisationWorkloads,
  RouteOrganisationCatalogue,
  RouteOrganisationUsers,
  RouteOrganisationServices,
  RouteOrganisationPolicies,
  RouteOrganisationOrganisationalUnits,
} = Data.taggedEnum<OrganisationRoute>()

const ACTION_CONFIGURE_REGION = "configure-region"
const ACTION_CONFIGURE_SECURITY_HUB = "configure-security-hub"

const schemaComponent = Schema.Struct({
  name: Schema.String,
})

type Component = typeof schemaComponent.Type

const withComponent =
  (fn: (name: string) => AppRoute) => (context: RouteContext) => {
    const result = Schema.decodeUnknownEither(schemaComponent)(context.params)

    return Either.match(result, {
      onLeft: () => RouteNotFound(),
      onRight: (data: Component) => fn(data.name),
    })
  }

const schemaCustomerId = Schema.Struct({
  customerId: Schema.String,
})

const withCustomerId =
  (fn: (cusId: string) => AppRoute) => (context: RouteContext) => {
    const result = Schema.decodeUnknownEither(schemaCustomerId)(context.params)

    return Either.match(result, {
      onLeft: () => RouteNotFound(),
      onRight: (data) => fn(data.customerId),
    })
  }

const schemaCustomerIdAndOrgId = Schema.Struct({
  customerId: Schema.String,
  orgId: Schema.String,
})

type CustomerIdAndOrgId = typeof schemaCustomerIdAndOrgId.Type

const withCustomerIdAndOrgId =
  (fn: (cusId: string, orgId: string) => AppRoute) =>
  (context: RouteContext) => {
    const result = Schema.decodeUnknownEither(schemaCustomerIdAndOrgId)(
      context.params,
    )

    return Either.match(result, {
      onLeft: () => RouteNotFound(),
      onRight: (data: CustomerIdAndOrgId) => fn(data.customerId, data.orgId),
    })
  }

const routesV2: Array<Route> = [
  {
    name: "RouteRoot",
    path: "",
    action: () => RouteRoot(),
  },
  {
    name: "RouteDevShowcase",
    path: "/dev/showcase",
    action: () => RouteDevShowcase(),
  },
  // Auth
  {
    name: "RouteInAuth.RouteAuthLogin",
    path: "/auth/login",
    action: () => RouteInAuth({ sub: RouteAuthLogin() }),
  },
  {
    name: "RouteInAuth.RouteAuthCallback",
    path: "/auth/redirect",
    action: () => RouteInAuth({ sub: RouteAuthCallback() }),
  },
  // Path needs to match was is configured in Cognito
  {
    name: "RouteInAuth.RouteAuthSignOut",
    path: "/signout",
    action: () => RouteInAuth({ sub: RouteAuthSignOut() }),
  },
  // Components
  {
    name: "RouteComponents",
    path: "/components",
    action: () => RouteComponents(),
  },
  {
    name: "RouteComponent",
    path: "/components/:name",
    action: withComponent((name) => RouteComponent({ name })),
  },
  // Customers
  {
    name: "RouteCustomers",
    path: "/customers",
    action: () => RouteCustomers(),
  },
  {
    path: "/customers/:customerId",
    children: [
      {
        name: "RouteInCustomer.RouteCustomerShow",
        path: "",
        action: withCustomerId((customerId) =>
          RouteInCustomer({
            customerId,
            sub: RouteCustomerShow(),
          }),
        ),
      },
      {
        name: "RouteInCustomer.RouteCustomerAddOrganisation",
        path: "/organisations/new",
        action: withCustomerId((customerId) =>
          RouteInCustomer({
            customerId,
            sub: RouteCustomerAddOrganisation(),
          }),
        ),
      },
    ],
  },
  {
    path: "/customers/:customerId/organisations/:orgId",
    children: [
      {
        path: "",
        children: [
          {
            name: "RouteInOrganisation.RouteOrganisationShow",
            path: "",
            action: withCustomerIdAndOrgId((customerId, orgId) =>
              RouteInOrganisation({
                customerId,
                orgId,
                sub: RouteOrganisationShow(),
              }),
            ),
          },
          {
            name: "RouteInOrganisation.RouteOrganisationWorkloads",
            path: "/workloads",
            action: withCustomerIdAndOrgId((customerId, orgId) =>
              RouteInOrganisation({
                customerId,
                orgId,
                sub: RouteOrganisationWorkloads(),
              }),
            ),
          },
          {
            name: "RouteInOrganisation.RouteOrganisationCatalogue",
            path: "/catalogue",
            action: withCustomerIdAndOrgId((customerId, orgId) =>
              RouteInOrganisation({
                customerId,
                orgId,
                sub: RouteOrganisationCatalogue(),
              }),
            ),
          },
          {
            name: "RouteInOrganisation.RouteOrganisationUsers",
            path: "/users",
            action: withCustomerIdAndOrgId((customerId, orgId) =>
              RouteInOrganisation({
                customerId,
                orgId,
                sub: RouteOrganisationUsers(),
              }),
            ),
          },
          {
            name: "RouteInOrganisation.RouteOrganisationServices",
            path: "/services",
            action: withCustomerIdAndOrgId((customerId, orgId) =>
              RouteInOrganisation({
                customerId,
                orgId,
                sub: RouteOrganisationServices(),
              }),
            ),
          },
          {
            name: "RouteInOrganisation.RouteOrganisationPolicies",
            path: "/policies",
            action: withCustomerIdAndOrgId((customerId, orgId) =>
              RouteInOrganisation({
                customerId,
                orgId,
                sub: RouteOrganisationPolicies(),
              }),
            ),
          },
          {
            name: "RouteInOrganisation.RouteOrganisationOrganisationalUnits",
            path: "/organisational-units",
            action: withCustomerIdAndOrgId((customerId, orgId) =>
              RouteInOrganisation({
                customerId,
                orgId,
                sub: RouteOrganisationOrganisationalUnits(),
              }),
            ),
          },
        ],
      },
    ],
  },
  // Provision
  {
    name: "RouteProvision",
    path: "/provision",
    action: (_context: RouteContext) => RouteProvision(),
  },
  {
    name: "RouteProvisionAddBillingRoot",
    path: "/provision/billing-roots/new",
    action: (_context: RouteContext) => RouteProvisionAddBillingRoot(),
  },
  {
    name: "RouteProvisionAddCustomer",
    path: "/provision/customers/new",
    action: (_context: RouteContext) => RouteProvisionAddCustomer(),
  },
  {
    name: "RouteProvisionAddOrganisation",
    path: "/provision/organisations/new",
    action: (_context: RouteContext) => RouteProvisionAddOrganisation(),
  },
  {
    name: "RouteProvisionVerify",
    path: "/provision/verify",
    action: (_context: RouteContext) => RouteProvisionVerify(),
  },
]

const router = new UniversalRouter(routesV2)

export const resolveRoute = (pathname: string): AppRoute => {
  try {
    return router.resolve(pathname)
  } catch (_error) {
    return { _tag: "RouteNotFound" }
  }
}

export const resolveLocation = (location: Location): AppLocation => {
  const route = resolveRoute(location.pathname)
  const query = queryString.parse(location.search)

  return {
    route,
    query,
  }
}

export const generate = (route: AppRoute): string => {
  const topName = route._tag

  if (topName === "RouteInAuth") {
    const name = `${topName}.${route.sub._tag}`
    return generateUrls(router)(name)
  }

  if (topName === "RouteInCustomer") {
    const name = `${topName}.${route.sub._tag}`
    const customerId = route.customerId
    const args = { customerId }
    return generateUrls(router)(name, args)
  }

  if (route._tag === "RouteInOrganisation") {
    const name = `${topName}.${route.sub._tag}`
    const customerId = route.customerId
    const orgId = route.orgId
    const args = { customerId, orgId }
    return generateUrls(router)(name, args)
  }

  if (topName === "RouteComponent") {
    const args = { name: route.name }
    return generateUrls(router)(topName, args)
  }

  return generateUrls(router)(topName)
}

export const generateWithHost = (host: string, route: AppRoute) => {
  return `${host}${generate(route)}`
}

export const urlCustomerConfigureRegion = () =>
  `?action=${ACTION_CONFIGURE_REGION}`

export const urlCustomerConfigureSecurityHub = () =>
  `?action=${ACTION_CONFIGURE_SECURITY_HUB}`

// Get routes
export const routeAuthSignIn = () => RouteInAuth({ sub: RouteAuthLogin() })

export const routeAuthSignOut = () => RouteInAuth({ sub: RouteAuthSignOut() })

export const routeAuthCallback = () => RouteInAuth({ sub: RouteAuthCallback() })

export const routeDashboard = () => RouteRoot()

export const routeComponent = (name: string): AppRoute =>
  RouteComponent({ name })

export const routeCustomer = (customerId: string) =>
  RouteInCustomer({ customerId, sub: RouteCustomerShow() })

export const routeOrganisation = (customerId: string, orgId: string) =>
  RouteInOrganisation({
    customerId,
    orgId,
    sub: RouteOrganisationShow(),
  })

export const routeCustomerAddOrganisation = (customerId: string) => {
  return RouteInCustomer({ customerId, sub: RouteCustomerAddOrganisation() })
}

export const routeOrganisationServices = (customerId: string, orgId: string) =>
  RouteInOrganisation({
    customerId,
    orgId,
    sub: RouteOrganisationServices(),
  })

export const routeProvision = () => {
  return RouteProvision()
}

export const routeProvisionAddCustomer = () => {
  return RouteProvisionAddCustomer()
}

export const routeProvisionAddOrganisation = () => {
  return RouteProvisionAddOrganisation()
}

export const routeProvisionVerify = () => {
  return RouteProvisionVerify()
}

// Fetch paths
export const pathLogin = () => generate(RouteInAuth({ sub: RouteAuthLogin() }))

export const pathAuthCallback = () =>
  generate(RouteInAuth({ sub: RouteAuthCallback() }))

export const pathAuthSignOut = () =>
  generate(RouteInAuth({ sub: RouteAuthSignOut() }))

export const pathProvisionAddCustomer = () =>
  generate(RouteProvisionAddCustomer())

// Route assertions

export const isAuthLogin = (route: AppRoute): boolean => {
  return route._tag === "RouteInAuth" && route.sub._tag === "RouteAuthLogin"
}

export const isAuthSignOut = (route: AppRoute): boolean => {
  return route._tag === "RouteInAuth" && route.sub._tag === "RouteAuthSignOut"
}

export const isAuthCallback = (route: AppRoute): boolean => {
  return route._tag === "RouteInAuth" && route.sub._tag === "RouteAuthCallback"
}

export const isInAnyComponent = (location: AppLocation): boolean => {
  // TODO, check if a component name is selected
  return location.route._tag === "RouteComponent"
}

export const isInCustomerConfigureSecurityHub = (
  currentLocation: AppLocation,
): boolean => {
  const { route, query } = currentLocation
  const isInOrganisation = route._tag === "RouteInOrganisation"
  const action = getActionFromQuery(query)

  return isInOrganisation && action === ACTION_CONFIGURE_SECURITY_HUB
}

export const isInCustomerConfigureRegion = (
  currentLocation: AppLocation,
): boolean => {
  const { route, query } = currentLocation
  const isInOrganisation = route._tag === "RouteInOrganisation"
  const action = getActionFromQuery(query)

  return isInOrganisation && action === ACTION_CONFIGURE_REGION
}

const getActionFromQuery = (query: queryString.Query) => {
  return pipe(query, queryString.getStringParam("action"), Either.getOrNull)
}
