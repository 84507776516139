import * as times from "~/common/utils/times"
import classNames from "classnames"
import { Option } from "effect"

type Props = {
  time: string
  className?: string
  isNew?: boolean
}

export const ComponentTime = (props: Props) => {
  const { time } = props

  const parsedTime = Option.match(times.parseISO(time), {
    onNone: () => "Invalid time",
    onSome: (time) => times.formatDistance(time, times.now()),
  })

  return (
    <div className={classNames("text-slate-500", props.className)}>
      {props.isNew ? "Merged" : "Last deployed"} {parsedTime} ago
    </div>
  )
}
