import type React from "react"
import type { AppUser } from "~/models/AppUser"

type Props = {
  currentUser: AppUser
  no?: React.ReactElement | null
  requiredRole?: Role
  yes?: React.ReactElement | null
}

export enum Role {
  readonly = "app-jumaadmin-readonly",
  customersuccess = "app-jumaadmin-customersuccess",
  finance = "app-jumaadmin-finance",
  customersupport = "app-jumaadmin-customersupport",
  deployer = "app-jumaadmin-deployer",
  admin = "app-jumaadmin-admin",
}

const RoleHierarchy = {
  "app-jumaadmin-readonly": 0,
  "app-jumaadmin-customersuccess": 1,
  "app-jumaadmin-finance": 2,
  "app-jumaadmin-customersupport": 3,
  "app-jumaadmin-deployer": 4,
  "app-jumaadmin-admin": 5,
}

export const check = (
  requiredRole: Role,
  idamRoles: ReadonlyArray<string> = [],
) => {
  const requiredRoleLevel = RoleHierarchy[requiredRole]

  for (const grant of idamRoles) {
    const isAdmin = grant === Role.admin

    const roleLevel: number = RoleHierarchy[grant as Role] || 0

    if (isAdmin || roleLevel >= requiredRoleLevel) {
      return true
    }
  }

  return false
}

export const Can = (props: Props) => {
  const { requiredRole, yes = null, no = null, currentUser } = props
  const allowed = userIsAllowed(currentUser, requiredRole)

  return allowed ? yes : no
}

const userIsAllowed = (
  currentUser: AppUser,
  requiredRole: Role | undefined,
) => {
  const idamRoles = currentUser.idamRoles ?? []

  if (requiredRole && idamRoles) {
    return check(requiredRole, idamRoles)
  }

  return false
}

type CanAnyProps = {
  currentUser: AppUser
  requiredRoles?: Array<Role>
  yes?: React.ReactElement | null
  no?: React.ReactElement | null
}

export const CanAny = (props: CanAnyProps) => {
  const { requiredRoles, yes = null, no = null, currentUser } = props

  const idamRoles = currentUser.idamRoles || new Array<string>()

  const checkRole = (role: Role) => check(role, idamRoles)

  if (requiredRoles && idamRoles) {
    return requiredRoles.some(checkRole) ? yes : no
  }

  return no
}

type IsAnyProps = {
  currentUser: AppUser
  requiredRoles?: [Role]
  yes?: React.ReactElement | null
  no?: React.ReactElement | null
}

export const IsAny = (props: IsAnyProps) => {
  const { requiredRoles, yes = null, no = null, currentUser } = props

  const idamRoles = currentUser.idamRoles || new Array<string>()

  const checkRole = (role: Role) => idamRoles.includes(role)

  if (requiredRoles && idamRoles) {
    return requiredRoles.some(checkRole) ? yes : no
  }

  return no
}
