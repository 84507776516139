import { Schema } from "@effect/schema"

export const OrganisationalUnitSchema = Schema.Struct({
  CreatedBy: Schema.String,
  CreatedTS: Schema.String,
  ExternalResource: Schema.Boolean,
  Id: Schema.String,
  ModifiedTS: Schema.String,
  Name: Schema.String,
  OrganisationId: Schema.String,
  OrganisationalUnitType: Schema.String,
  ParentOrganisationalUnitId: Schema.OptionFromNullishOr(
    Schema.String,
    undefined,
  ),
  Status: Schema.String,
  UserTaskId: Schema.String,
})

export type OrganisationalUnit = typeof OrganisationalUnitSchema.Type

export const OrganisationalUnitsResponseSchema = Schema.Struct({
  OrganisationalUnits: Schema.Array(OrganisationalUnitSchema),
})
