import * as log from "~/common/utils/log"
import * as provisionApi from "~/common/api/provisionApiV2"
import type React from "react"
import { ButtonOutline } from "~/common/ui/Buttons"
import { FormTextInput, FormSelect } from "~/common/ui/Forms"
import { useField, Formik, type FormikProps, Form } from "formik"
import {
  computeFieldStyleProps,
  renderSelectHelperTextV2,
  validateRequired,
  validateAwsId,
  validateEmail,
} from "~/common/utils/provision"

enum ACCOUNT_ORIGIN {
  STAX = "STAX",
  EXTERNAL = "EXTERNAL",
}

enum FIELDS {
  ACCOUNT_EMAIL = "accountEmail",
  ACCOUNT_ID = "accountId",
  ACCOUNT_ORIGIN = "accountOrigin",
}

const FIELD_HELPER_TEXTS = {
  [FIELDS.ACCOUNT_EMAIL]: "The Email associated with the billing root account",
  [FIELDS.ACCOUNT_ID]: "The AWS Account Id of the billing account",
}

type State = {
  accountEmail: string
  accountId: string
  accountOrigin: ACCOUNT_ORIGIN
}

const INITIAL_STATE: State = {
  accountEmail: "",
  accountId: "",
  accountOrigin: ACCOUNT_ORIGIN.STAX,
}

type Props = FormikProps<typeof INITIAL_STATE>

const AddBillingRootForm: React.FC<Props> = ({ handleSubmit }) => {
  const [
    accountOriginField,
    { touched: accountOriginTouched, error: accountOriginError },
  ] = useField({ name: FIELDS.ACCOUNT_ORIGIN, validate: validateRequired })

  const [
    accountEmailField,
    { touched: accountEmailTouched, error: accountEmailError },
  ] = useField({ name: FIELDS.ACCOUNT_EMAIL, validate: validateEmail })

  const [accountIdField, { touched: accountIdTouched, error: accountIdError }] =
    useField({ name: FIELDS.ACCOUNT_ID, validate: validateAwsId })

  return (
    <Form onSubmit={handleSubmit}>
      <FormSelect
        label="Account Origin"
        name={accountOriginField.name}
        onBlur={accountOriginField.onBlur}
        onChange={accountOriginField.onChange}
        required={true}
        value={accountOriginField.value}
        {...renderSelectHelperTextV2(accountOriginError, accountOriginTouched)}
      >
        {Object.values(ACCOUNT_ORIGIN).map((origin) => (
          <option value={origin} key={origin}>
            {origin}
          </option>
        ))}
      </FormSelect>

      <FormTextInput
        id={accountEmailField.name}
        label="AWS Account Email*"
        required={true}
        {...accountEmailField}
        {...computeFieldStyleProps(
          accountEmailError,
          accountEmailTouched,
          FIELD_HELPER_TEXTS[FIELDS.ACCOUNT_EMAIL],
        )}
      />

      <FormTextInput
        id={accountIdField.name}
        label="AWS Account Id*"
        required={true}
        {...accountIdField}
        {...computeFieldStyleProps(
          accountIdError,
          accountIdTouched,
          FIELD_HELPER_TEXTS[FIELDS.ACCOUNT_ID],
        )}
      />

      <ButtonOutline type="submit">Add billing root</ButtonOutline>
    </Form>
  )
}

const onSubmitAddBillingRoot = async (values: typeof INITIAL_STATE) => {
  const payload = {
    AccountId: values.accountId,
    AccountEmail: values.accountEmail,
    AccountOrigin: values.accountOrigin,
  }

  return provisionApi
    .addRoot(payload)
    .then((apiResponse) => {
      if (apiResponse.statusCode === 200) {
        // TODO, use a toast
        window.alert("Success! Factory execution started")
      } else {
        log.error(apiResponse)
        window.alert("An unknown error occurred")
      }
    })
    .catch((error) => {
      log.error(error)
      window.alert("An unknown error occurred")
    })
}

export const ProvisionAddBillingRoot = () => {
  return (
    <Formik
      initialValues={INITIAL_STATE}
      validateOnChange={false}
      onSubmit={onSubmitAddBillingRoot}
    >
      {(formikProps) => <AddBillingRootForm {...formikProps} />}
    </Formik>
  )
}
