import { type IdamConfig, IdamConfigsResponseSchema } from "~/models/IdamConfig"
import type { RemoteData } from "~/models/RemoteData"
import { Schema } from "@effect/schema"
import { useQuery } from "~/common/hooks/useQuery"

export const useOrgIdamConfig = (
  orgId: string,
): {
  remoteData: RemoteData<ReadonlyArray<IdamConfig>, unknown>
} => {
  const { remoteData } = useQuery({
    url: `/organisations/${orgId}/idam/config`,
    parse: Schema.decodeUnknownEither(IdamConfigsResponseSchema),
    shouldFetch: orgId !== "_",
  })

  return { remoteData }
}
