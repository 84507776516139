import type { Context } from "~/models/Context"
import { NarrowContainerWithPadding } from "~/common/ui/Panels"
import { Page } from "~/common/ui/Page"
import { ProvisionAddOrg } from "~/widgets/ProvisionAddOrg"

type Props = {
  context: Context
  customerId?: string
}

export const PageProvisionAddOrg = (props: Props) => {
  const { customerId } = props

  return (
    <Page context={props.context} title="Provision">
      <NarrowContainerWithPadding>
        <h2 className="text-lg pb-4">
          Add new organisation to a Stax customer
        </h2>
        <ProvisionAddOrg selectedCustomerId={customerId} />
      </NarrowContainerWithPadding>
    </Page>
  )
}
