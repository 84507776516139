import type React from "react"
import type { Context } from "~/models/Context"
import { Loading } from "~/common/ui/Loading"
import { Navigation } from "~/widgets/Navigation"
import { NoResults } from "~/common/ui/NoResults"
import { type PropsWithChildren, Fragment } from "react"

type Props = PropsWithChildren<{
  actions?: React.ReactNode
  context: Context
  title: string
}>

export function Page(props: Props) {
  return (
    <Fragment>
      <Navigation
        context={props.context}
        pageTitle={props.title}
        pageActions={props.actions}
      />
      <main className="t-page flex justify-center">
        <div style={{ width: "var(--app-width)" }} className="pt-4 pb-12">
          {props.children}
        </div>
      </main>
    </Fragment>
  )
}

export const PageIsLoading = (props: Props) => {
  return (
    <Page context={props.context} title={props.title} actions={props.actions}>
      <Loading classname="pb-12" />
    </Page>
  )
}

export const PageFailure = (props: Props & { message: string }) => {
  return (
    <Page context={props.context} title={props.title} actions={props.actions}>
      <NoResults title={props.message} />
    </Page>
  )
}

export const PageNotFound = (props: Props & { message: string }) => {
  return (
    <Page context={props.context} title={props.title} actions={props.actions}>
      <NoResults title={props.message} />
    </Page>
  )
}
