import classNames from "classnames"
import history from "history/browser"
import type React from "react"

type Props = React.PropsWithChildren<{
  className?: string
  disabled?: boolean
  href: string
  title?: string | undefined
}>

export const Link = (props: Props) => {
  let onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    history.push(props.href)
  }

  let classes = classNames({ "t-link": true }, props.className)

  return props.disabled ? (
    <span className={classes} title={props.title}>
      {props.children}
    </span>
  ) : (
    <a
      className={classes}
      href={props.href}
      onClick={onClick}
      title={props.title}
    >
      {props.children}
    </a>
  )
}
