import * as navigation from "~/common/utils/navigation"
import * as provisionApi from "~/common/api/provisionApiV2"
import * as log from "~/common/utils/log"
import * as routes from "~/routes"
import React from "react"
import type { Account } from "~/models/Account"
import { ButtonOutline } from "~/common/ui/Buttons"
import type { Customer } from "~/models/Customer"
import { Loading } from "~/common/ui/Loading"
import { NoResults } from "~/common/ui/NoResults"
import { useAccountBillingRoots } from "~/common/hooks/useAccountBillingRoots"
import { useCustomerOrgs } from "~/common/hooks/useCustomerOrgs"
import { useCustomers } from "~/common/hooks/useCustomers"
import { useField, Formik, type FormikProps, Form } from "formik"

import {
  FormTextInput,
  FormSectionTitle,
  FormSelect,
  RadioWithLabel,
  RadioGroup,
  FormField,
} from "~/common/ui/Forms"

import {
  FIELDS,
  AWS_SUPPORT_TYPES,
  CUSTOMER_TYPES,
  FIELD_HELPER_TEXTS,
  ORG_FORM_INITIAL_STATE,
} from "~/common/utils/constantsProvision"

import {
  computeFieldStyleProps,
  renderSelectHelperTextV2,
  validateRequired,
  parseAndSetField,
} from "~/common/utils/provision"
import type { Organisation } from "~/models/Organisation"

const useCustomerDefaultOrg = (
  selectedCustomerId: string | undefined,
): Organisation | undefined => {
  const { remoteData } = useCustomerOrgs({
    customerId: selectedCustomerId,
  })

  if (selectedCustomerId == null) return undefined

  if (
    remoteData._tag === "Loading" ||
    remoteData._tag === "NotAsked" ||
    remoteData._tag === "Failure"
  ) {
    return undefined
  }

  const { Organisations: orgs } = remoteData.data

  return orgs.find((org) => org.Name === "default")
}

type FormProps = FormikProps<typeof ORG_FORM_INITIAL_STATE> &
  Props & {
    customers: ReadonlyArray<Customer>
    preSelectedCustomer: Customer | undefined
    unusedBillingRoots: ReadonlyArray<Account>
  }

const AddOrgForm = (props: FormProps) => {
  const { customers, preSelectedCustomer, handleSubmit, unusedBillingRoots } =
    props

  const [
    customerIdField,
    { error: customerIdError, touched: customerIdTouched },
  ] = useField({ name: FIELDS.CUSTOMER, validate: validateRequired })

  const customerTypeDirect =
    preSelectedCustomer && preSelectedCustomer.Type === CUSTOMER_TYPES.DIRECT

  const defaultOrg = useCustomerDefaultOrg(preSelectedCustomer?.Id)

  const [marketplaceSignupField, _, marketplaceFieldHelperProps] = useField({
    name: FIELDS.MARKETPLACE_SIGNUP,
    validate: validateRequired,
  })

  const [
    billingRootField,
    { touched: billingRootTouched, error: billingRootError },
  ] = useField({ name: FIELDS.BILLING_ROOT, validate: validateRequired })

  const [orgAliasField, { touched: orgAliasTouched, error: orgAliasError }] =
    useField({ name: FIELDS.ORG_ALIAS, validate: validateRequired })

  const [
    emailTemplateField,
    { touched: emailTemplateTouched, error: emailTemplateError },
  ] = useField({
    name: FIELDS.EMAIL_TEMPLATE,
    validate: validateRequired,
  })

  const [
    awsSupportTypeField,
    { error: awsSupportTypeError, touched: awsSupportTypeTouched },
  ] = useField({ name: FIELDS.AWS_SUPPORT_TYPE, validate: validateRequired })

  const [partnerSupportField] = useField({
    name: FIELDS.PARTNER_SUPPORT,
    validate: !customerTypeDirect ? validateRequired : () => undefined,
  })

  const [salesforceIdField] = useField({
    name: FIELDS.SALESFORCE_ID,
    validate: validateRequired,
  })

  const [spotlightIdField] = useField({ name: FIELDS.SPOTLIGHT_ID })

  const [
    idamUserEmailField,
    { touched: idamUserEmailTouched, error: idamUserEmailError },
  ] = useField({ name: FIELDS.IDAM_USER_EMAIL, validate: validateRequired })

  const [
    idamUserFirstNameField,
    { touched: idamUserFirstNameTouched, error: idamUserFirstNameError },
  ] = useField({
    name: FIELDS.IDAM_USER_FIRST_NAME,
    validate: validateRequired,
  })

  const [
    idamUserLastNameField,
    { touched: idamUserLastNameTouched, error: idamUserLastNameError },
  ] = useField({
    name: FIELDS.IDAM_USER_LAST_NAME,
    validate: validateRequired,
  })

  React.useEffect(() => {
    if (defaultOrg?.Id) {
      marketplaceFieldHelperProps.setValue(
        Boolean(Number(defaultOrg.MarketplaceSignup)),
      )
      // marketplaceMetaProps.touched
    }
  }, [
    defaultOrg?.Id,
    defaultOrg?.MarketplaceSignup,
    marketplaceFieldHelperProps,
  ])

  return (
    <Form onSubmit={handleSubmit}>
      <FormSelect
        disabled={preSelectedCustomer != null}
        label="Customer"
        name={customerIdField.name}
        onBlur={customerIdField.onBlur}
        onChange={customerIdField.onChange}
        required={true}
        value={customerIdField.value}
        {...renderSelectHelperTextV2(customerIdError, customerIdTouched)}
      >
        <option disabled value="">
          Select a customer
        </option>
        {customers.map((customer) => (
          <option value={customer.Id} key={customer.Id}>
            {customer.Name}
          </option>
        ))}
      </FormSelect>

      {customerTypeDirect === false ? (
        <FormField
          id={partnerSupportField.name}
          label="Partner Support?"
          required={false}
        >
          <RadioGroup>
            <RadioWithLabel
              checked={partnerSupportField.value === true}
              label="Yes"
              name={partnerSupportField.name}
              onChange={parseAndSetField(partnerSupportField.onChange)}
              value={true}
            />

            <RadioWithLabel
              checked={partnerSupportField.value === false}
              label="No"
              name={partnerSupportField.name}
              onChange={parseAndSetField(partnerSupportField.onChange)}
              value={false}
            />
          </RadioGroup>
        </FormField>
      ) : null}

      {defaultOrg ? (
        <FormField
          id={marketplaceSignupField.name}
          label="AWS Marketplace Signup?"
          required={false}
        >
          <RadioGroup>
            <RadioWithLabel
              checked={marketplaceSignupField.value}
              label="Yes"
              name={marketplaceSignupField.name}
              onChange={parseAndSetField(marketplaceSignupField.onChange)}
              value={true}
            />

            <RadioWithLabel
              checked={!marketplaceSignupField.value}
              label="No"
              name={marketplaceSignupField.name}
              onChange={parseAndSetField(marketplaceSignupField.onChange)}
              value={false}
            />
          </RadioGroup>
        </FormField>
      ) : null}

      <FormSelect
        label="Billing Root"
        name={billingRootField.name}
        onBlur={billingRootField.onBlur}
        onChange={billingRootField.onChange}
        required={true}
        value={billingRootField.value}
        {...renderSelectHelperTextV2(billingRootError, billingRootTouched)}
      >
        <option disabled value="">
          Select a billing root
        </option>
        {unusedBillingRoots.map((root: Account) => (
          <option value={root.AwsAccountId} key={root.AwsAccountId}>
            {root.Email}
          </option>
        ))}
      </FormSelect>

      <FormTextInput
        id={orgAliasField.name}
        label="Alias"
        required={true}
        {...orgAliasField}
        {...computeFieldStyleProps(
          orgAliasError,
          orgAliasTouched,
          FIELD_HELPER_TEXTS[FIELDS.ORG_ALIAS],
        )}
      />

      <FormTextInput
        id={emailTemplateField.name}
        label="Email domain template"
        required={true}
        {...emailTemplateField}
        {...computeFieldStyleProps(
          emailTemplateError,
          emailTemplateTouched,
          "e.g. aws-${Stax::AccountId}@fakecompany.com",
        )}
      />

      <FormSelect
        label="AWS Support Type"
        name={awsSupportTypeField.name}
        onBlur={awsSupportTypeField.onBlur}
        onChange={awsSupportTypeField.onChange}
        required={true}
        value={awsSupportTypeField.value}
        {...renderSelectHelperTextV2(
          awsSupportTypeError,
          awsSupportTypeTouched,
        )}
      >
        {Object.values(AWS_SUPPORT_TYPES).map((awsSupportType) => (
          <option value={awsSupportType} key={awsSupportType}>
            {awsSupportType}
          </option>
        ))}
      </FormSelect>

      <FormTextInput
        hasError={false}
        helperText={FIELD_HELPER_TEXTS[FIELDS.SPOTLIGHT_ID]}
        id={spotlightIdField.name}
        label="Spotlight External ID"
        required={false}
        {...spotlightIdField}
      />

      <FormTextInput
        hasError={false}
        helperText={FIELD_HELPER_TEXTS[FIELDS.SALESFORCE_ID]}
        id={salesforceIdField.name}
        label="Salesforce ID"
        required={true}
        {...salesforceIdField}
      />

      <FormSectionTitle>First IDAM User</FormSectionTitle>

      <FormTextInput
        id={idamUserEmailField.name}
        label="Email"
        required={true}
        {...idamUserEmailField}
        {...computeFieldStyleProps(
          idamUserEmailError,
          idamUserEmailTouched,
          FIELD_HELPER_TEXTS[FIELDS.IDAM_USER_EMAIL],
        )}
      />

      <FormTextInput
        id={idamUserFirstNameField.name}
        label="First Name"
        required={true}
        {...idamUserFirstNameField}
        {...computeFieldStyleProps(
          idamUserFirstNameError,
          idamUserFirstNameTouched,
          FIELD_HELPER_TEXTS[FIELDS.IDAM_USER_FIRST_NAME],
        )}
      />

      <FormTextInput
        id={idamUserLastNameField.name}
        label="Last Name"
        required={true}
        {...idamUserLastNameField}
        {...computeFieldStyleProps(
          idamUserLastNameError,
          idamUserLastNameTouched,
          FIELD_HELPER_TEXTS[FIELDS.IDAM_USER_LAST_NAME],
        )}
      />

      <ButtonOutline type="submit">Create Organisation</ButtonOutline>
    </Form>
  )
}

type Props = {
  selectedCustomerId?: string | undefined
  marketplaceSignupValue?: boolean
}

const onSubmitProvisionAddOrg =
  (selectedCustomerId: string | undefined) =>
  async (values: typeof ORG_FORM_INITIAL_STATE) => {
    const payload = {
      CustomerId: values.customerId,
      BillingAccount: values.billingRoot,
      OrgAlias: values.orgAlias,
      MarketplaceSignup: values.marketplaceSignup,
      AwsSupportType: values.awsSupportType,
      IdamUserEmail: values.idamUserEmail,
      IdamUserFirstName: values.idamUserFirstName,
      IdamUserLastName: values.idamUserLastName,
    }

    // Optional payload keys
    if (values.spotlightId)
      Object.assign(payload, { SpotlightId: values.spotlightId })

    if (values.emailTemplate)
      Object.assign(payload, { EmailTemplate: values.emailTemplate })

    if (values.salesforceId)
      Object.assign(payload, { SalesforceId: values.salesforceId })

    if (values.partnerSupport) {
      Object.assign(payload, { AwsPartnerSupport: values.partnerSupport })
    } else {
      Object.assign(payload, { AwsPartnerSupport: false })
    }

    const nextRoute = selectedCustomerId
      ? routes.routeCustomer(selectedCustomerId)
      : routes.routeProvision()

    return provisionApi
      .addOrganisation(payload)
      .then((apiResponse) => {
        if (apiResponse.statusCode === 200) {
          // TODO, use a toast
          window.alert("Success! Factory execution started")
          navigation.navigateToRoute(nextRoute)
        } else {
          log.error(apiResponse)
          window.alert("An unknown error occurred")
        }
      })
      .catch((error) => {
        log.error(error)
        window.alert("An unknown error occurred")
      })
  }

export const ProvisionAddOrg = (props: Props) => {
  const { selectedCustomerId } = props

  const { remoteData: remoteDataCustomers } = useCustomers()

  const { remoteData: remoteDataUnusedBillingRoots } =
    useAccountBillingRoots("UNUSED")

  const isLoadingCustomers =
    remoteDataCustomers._tag === "Loading" ||
    remoteDataCustomers._tag === "NotAsked"

  const isLoadingBillingRoots =
    remoteDataUnusedBillingRoots._tag === "Loading" ||
    remoteDataUnusedBillingRoots._tag === "NotAsked"

  if (isLoadingCustomers || isLoadingBillingRoots) {
    return <Loading classname="pb-12" />
  }

  if (remoteDataCustomers._tag === "Failure") {
    log.error(remoteDataCustomers.error)
    return <NoResults title="Error fetching Customers" />
  }

  if (remoteDataUnusedBillingRoots._tag === "Failure") {
    log.error(remoteDataUnusedBillingRoots.error)
    return <NoResults title="Error fetching Billing Roots" />
  }

  const { Customers: customers } = remoteDataCustomers.data
  const { Accounts: unusedBillingRoots } = remoteDataUnusedBillingRoots.data

  const preSelectedCustomer = customers.find(
    (customer) => customer.Id === selectedCustomerId,
  )

  return (
    <Formik
      validateOnMount={true}
      initialValues={{
        ...ORG_FORM_INITIAL_STATE,
        customerId: preSelectedCustomer?.Id || "",
      }}
      validateOnChange={false}
      onSubmit={onSubmitProvisionAddOrg(selectedCustomerId)}
    >
      {(formikProps) => (
        <AddOrgForm
          customers={customers}
          preSelectedCustomer={preSelectedCustomer}
          unusedBillingRoots={unusedBillingRoots}
          {...formikProps}
        />
      )}
    </Formik>
  )
}
