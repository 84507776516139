import * as RadixSwitch from "@radix-ui/react-switch"

type SwitchProps = {
  checked: boolean
  disabled?: boolean
  name: string
  onChange: (checked: boolean) => void
}

export const Switch = (props: SwitchProps) => {
  return (
    <RadixSwitch.Root
      checked={props.checked}
      className="Switch"
      disabled={props.disabled}
      name={props.name}
      onCheckedChange={props.onChange}
    >
      <RadixSwitch.Thumb className="SwitchThumb" />
    </RadixSwitch.Root>
  )
}
