import type { Context } from "~/models/Context"
import { NarrowContainerWithPadding } from "~/common/ui/Panels"
import { Page } from "~/common/ui/Page"
import { ProvisionAddBillingRoot } from "~/widgets/ProvisionAddBillingRoot"

type Props = {
  context: Context
}

export const PageProvisionAddBillingRoot = (props: Props) => (
  <Page context={props.context} title="Provision">
    <NarrowContainerWithPadding>
      <h2 className="text-lg pb-4">
        Add New Billing Root to Stax Installation
      </h2>
      <ProvisionAddBillingRoot />
    </NarrowContainerWithPadding>
  </Page>
)
