import type { Context } from "~/models/Context"
import { NarrowContainerWithPadding } from "~/common/ui/Panels"
import { Page } from "~/common/ui/Page"
import { ProvisionAddCustomer } from "~/widgets/ProvisionAddCustomer"

type Props = {
  context: Context
}

export const PageProvisionAddCustomer = (props: Props) => (
  <Page context={props.context} title="Provision">
    <NarrowContainerWithPadding>
      <h2 className="text-lg pb-4">Add New Customer to Stax installation</h2>
      <ProvisionAddCustomer />
    </NarrowContainerWithPadding>
  </Page>
)
