import classNames from "classnames"
import type React from "react"

type Empty = Record<never, never>

type PanelProps = React.PropsWithChildren<{
  className?: string
}>

export const Panel = (props: PanelProps) => {
  const classes = classNames("bg-white", props.className)
  return <section className={classes}>{props.children}</section>
}

const NarrowContainer = (
  props: React.PropsWithChildren<{ className?: string }>,
) => {
  return (
    <div className="flex justify-center">
      <div
        style={{ width: "32rem" }}
        className={classNames("bg-white", props.className)}
      >
        {props.children}
      </div>
    </div>
  )
}

export const NarrowContainerWithPadding = (
  props: React.PropsWithChildren<Empty>,
) => {
  return <NarrowContainer className="p-4">{props.children}</NarrowContainer>
}
