import classNames from "classnames"
import type React from "react"
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid"

type Props = {
  className?: string
  style?: React.CSSProperties
}

export const NoDataIcon = (props: Props) => (
  <QuestionMarkCircleIcon
    className={classNames("fill-slate-300", props.className)}
    style={props.style}
  />
)
