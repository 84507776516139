import * as log from "~/common/utils/log"
import * as RemoteData from "~/models/RemoteData"
import { type AmplifyResponse, isSuccessCode } from "~/common/api/apiFetchers"
import { Either } from "effect"
import { Schema } from "@effect/schema"

const ErrorBodySchema = Schema.Struct({ Error: Schema.String })

export type ErrorBody = typeof ErrorBodySchema.Type

/*
Take a response from amplify
Return a RemoteData wrapping the raw response
This doesn't parse the body
*/
export const amplifyResponseToRemoteData = async (
  promise: Promise<AmplifyResponse>,
): Promise<RemoteData.RemoteData<AmplifyResponse, unknown>> => {
  return promise
    .then((amplifyResponse) => {
      if (isSuccessCode(amplifyResponse)) {
        return RemoteData.Success({ data: amplifyResponse })
      }
      return RemoteData.Failure({ error: "Something went wrong" })
    })
    .catch((error: Error) => {
      log.error(error)
      return RemoteData.Failure({ error: "Something went wrong" })
    })
}

/*
Take a response from amplify, which must have a JSON body
Parse the JSON body and return a RemoteData
*/
export const amplifyJsonResponseToRemoteData =
  <Data>(schema: Schema.Schema<Data>) =>
  async (
    promise: Promise<AmplifyResponse>,
  ): Promise<RemoteData.RemoteData<Data, string>> => {
    return promise
      .then((apiResponse) =>
        amplifyJsonResponseSuccessToRemoteData(apiResponse, schema),
      )
      .catch((error: Error) => {
        log.error(error)
        return RemoteData.Failure({ error: "Something went wrong" })
      })
  }

export const amplifyJsonResponseSuccessToRemoteData = async <Data>(
  response: AmplifyResponse,
  schema: Schema.Schema<Data>,
): Promise<RemoteData.RemoteData<Data, string>> => {
  const json = await response.body.json()

  if (isSuccessCode(response)) {
    const result = Schema.decodeUnknownEither(schema)(json)

    return Either.match(result, {
      onLeft: (parseError) => {
        log.error(parseError)

        return RemoteData.Failure({
          error: "Failed to parse response",
        })
      },
      onRight: (data) => {
        return RemoteData.Success({ data })
      },
    })
  }

  // Try to parse the body as error
  const errorBodyResult = Schema.decodeUnknownEither(ErrorBodySchema)(json)

  return Either.match(errorBodyResult, {
    onLeft: (parseError) => {
      log.error(parseError)

      return RemoteData.Failure({
        error: "Failed to parse error body",
      })
    },
    onRight: (errorBody) =>
      RemoteData.Failure({
        error: errorBody.Error,
      }),
  })
}
