import "aws-amplify/auth/enable-oauth-listener"
import * as log from "~/common/utils/log"
import * as routes from "~/routes"
import type React from "react"
import type { AppConfig } from "~/common/utils/config"
import { type AppUser, parseAppUserFromAmplify } from "~/models/AppUser"
import { BusyFullWidth } from "~/common/ui/Loading"
import { Data, Either } from "effect"
import { Hub } from "aws-amplify/utils"
import { navigateToRoute } from "~/common/utils/navigation"
import { PageAuthLogin } from "~/pages/PageAuthLogin"
import { signOut } from "aws-amplify/auth"
import {
  useElmish,
  cmd,
  type Dispatch,
  type InitResult,
  type SubscriptionResult,
  type UpdateReturnType,
} from "react-elmish"
import {
  fetchUserAttributes,
  type FetchUserAttributesOutput,
  getCurrentUser,
  type GetCurrentUserOutput,
} from "aws-amplify/auth"

type Empty = Record<never, never>

type User = Data.TaggedEnum<{
  UserUnknownState: Empty
  Unauthenticated: Empty
  UserProcessing: Empty
  Authenticated: { appUser: AppUser }
}>

const { UserUnknownState, UserProcessing, Authenticated } =
  Data.taggedEnum<User>()

type Model = {
  user: User
}

type InitProps = { currentRoute: routes.AppRoute }

type Message =
  | { name: "NoOp" }
  | { name: "SignedInWithRedirect" }
  | { name: "GotCurrentUser"; data: GetCurrentUserOutput }
  | { name: "GetCurrentUserFailed" }
  | { name: "GotUserAttributes"; data: FetchUserAttributesOutput }
  | { name: "GetUserAttributesFailed" }

const init = (_props: InitProps): InitResult<Model, Message> => {
  return [
    {
      user: UserUnknownState(),
    },
    cmd.batch(cmdGetCurrentUser, cmdGetUserAttributes),
  ]
}

const onGetCurrentUserSuccess = (data: GetCurrentUserOutput): Message => ({
  name: "GotCurrentUser",
  data,
})

const onGetCurrentUserFailure = (): Message => ({
  name: "GetCurrentUserFailed",
})

const onGetUserAttributesSuccess = (
  data: FetchUserAttributesOutput,
): Message => ({
  name: "GotUserAttributes",
  data,
})

const onGetUserAttributesFailure = (): Message => ({
  name: "GetUserAttributesFailed",
})

const cmdGetCurrentUser = cmd.ofEither(
  getCurrentUser,
  onGetCurrentUserSuccess,
  onGetCurrentUserFailure,
)

const cmdGetUserAttributes = cmd.ofEither(
  fetchUserAttributes,
  onGetUserAttributesSuccess,
  onGetUserAttributesFailure,
)

const navigateToSignIn = () => {
  navigateToRoute(routes.routeAuthSignIn())
}

const cmdNavigateToSignIn = cmd.ofSuccess(
  navigateToSignIn,
  (): Message => ({
    name: "NoOp",
  }),
)

const navigateToDashboard = () => {
  navigateToRoute(routes.routeDashboard())
}

const cmdNavigateToDashboard = cmd.ofSuccess(
  navigateToDashboard,
  (): Message => ({
    name: "NoOp",
  }),
)

const update = (
  model: Model,
  msg: Message,
  props: InitProps,
): UpdateReturnType<Model, Message> => {
  const isInSignIn = routes.isAuthLogin(props.currentRoute)
  const isInCallback = routes.isAuthCallback(props.currentRoute)

  switch (msg.name) {
    case "NoOp": {
      return [model]
    }
    case "SignedInWithRedirect": {
      return [
        { ...model, user: UserProcessing() },
        cmd.batch(cmdGetCurrentUser, cmdGetUserAttributes),
      ]
    }
    case "GotCurrentUser": {
      return [{ ...model }]
    }
    case "GetCurrentUserFailed": {
      return [model]
    }
    case "GotUserAttributes": {
      const appUserResult = parseAppUserFromAmplify(msg.data)

      if (Either.isLeft(appUserResult)) {
        log.error(appUserResult)
      }

      const appUser = Either.getOrThrow(appUserResult)

      const user = Authenticated({ appUser: appUser })
      const nextModel = { ...model, user: user }

      if (isInSignIn || isInCallback) {
        return [nextModel, cmdNavigateToDashboard]
      }

      return [nextModel]
    }
    case "GetUserAttributesFailed": {
      if (model.user._tag === "UserUnknownState") {
        return [model, cmdNavigateToSignIn]
      }

      log.error("GetUserAttributesFailed")
      return [model]
    }
  }
}

const subscription = (_model: Model): SubscriptionResult<Message> => {
  const sub = (dispatch: Dispatch<Message>): void => {
    Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signInWithRedirect":
          return dispatch({ name: "SignedInWithRedirect" })
        case "signInWithRedirect_failure":
          log.error(payload)
          break
      }
    })
  }

  return [cmd.ofSub(sub)]
}

type Props = {
  currentAppLocation: routes.AppLocation
  config: AppConfig
  children: (args: { user: AppUser }) => React.ReactNode
}

export const AuthWrapper = (props: Props) => {
  const [model, _dispatch] = useElmish<InitProps, Model, Message>({
    props: {
      currentRoute: props.currentAppLocation.route,
    },
    init,
    update,
    name: "AuthWrapper",
    subscription,
  })

  const isInSignIn = routes.isAuthLogin(props.currentAppLocation.route)
  const isInSignOut = routes.isAuthSignOut(props.currentAppLocation.route)
  const isInCallback = routes.isAuthCallback(props.currentAppLocation.route)

  const isUnknownState = model.user._tag === "UserUnknownState"
  const isAuthenticated = model.user._tag === "Authenticated"
  const isUnauthenticated = model.user._tag === "Unauthenticated"

  if (isInSignIn) {
    return <PageAuthLogin config={props.config} />
  }

  if (isInSignOut) {
    if (isAuthenticated) {
      signOut()
      return null
    }

    navigateToSignIn()
    return null
  }

  if (isInCallback) {
    if (isAuthenticated) {
      navigateToDashboard()
      return null
    }

    return <BusyFullWidth />
  }

  // Not an auth page

  if (isUnknownState) {
    return <BusyFullWidth />
  }

  if (isUnauthenticated) {
    navigateToSignIn()
    return null
  }

  if (model.user._tag === "Authenticated") {
    return props.children({ user: model.user.appUser })
  }

  return <BusyFullWidth />
}
