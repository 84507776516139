import * as componentsApi from "~/common/api/componentsApiV2"
import * as log from "~/common/utils/log"
import type * as routes from "~/routes"
import type React from "react"
import {
  ComponentSchema,
  type Component,
  type DeploymentStatus,
} from "~/models/Component"
import { ComponentItemContent } from "./ComponentList/ComponentItemContent"
import { ComponentListItem } from "./ComponentList/ComponentListItem"
import type { Context } from "~/models/Context"
import { NoResults } from "~/common/ui/NoResults"
import { debounce } from "dettle"
import { Loading } from "~/common/ui/Loading"
import { Panel } from "~/common/ui/Panels"
import { Schema } from "@effect/schema"
import { TextInput } from "~/common/ui/Forms"
import { useCallback } from "react"
import {
  useElmish,
  cmd,
  type InitResult,
  type UpdateReturnType,
} from "react-elmish"
import { useQuery } from "~/common/hooks/useQuery"

const ListResponseSchema = Schema.rename(
  Schema.Struct({
    Components: Schema.Array(ComponentSchema),
  }),
  {
    Components: "components",
  },
)

const filterComponent = (appliedQuery: string) => (component: Component) =>
  component.name.toLocaleLowerCase().includes(appliedQuery.toLowerCase())

const getComponentName = (currentAppLocation: routes.AppLocation) =>
  currentAppLocation.route._tag === "RouteComponent"
    ? currentAppLocation.route.name
    : undefined

type Message =
  | { name: "ApiSucceeded" }
  | { name: "ApiFailed" }
  | { name: "ChangedQuery"; query: string }
  | { name: "ChangedAppliedQuery"; query: string }
  | { name: "ComponentAutoDeployToggled"; component: Component; value: boolean }
  | { name: "ComponentAutoDeployToggledDone"; component: Component }
  | { name: "ComponentAutoDeployToggledFailed"; component: Component }
  | { name: "ComponentDeployed"; deploymentId: string }
  | { name: "ComponentDeployedDone"; deploymentId: string }
  | { name: "ComponentDeployedFailed"; deploymentId: string }
  | {
      name: "ComponentLocked"
      component: Component
      value: boolean
      comment: string
    }
  | {
      name: "ComponentLockedDone"
      component: Component
    }
  | {
      name: "ComponentLockedFailed"
      component: Component
    }

type Model = {
  appliedQuery: string
  query: string
  deploymentStatuses: { [key: string]: DeploymentStatus }
}

type InitProps = {
  query: string
}

const init = (props: InitProps): InitResult<Model, Message> => {
  return [
    {
      appliedQuery: props.query,
      deploymentStatuses: {},
      query: props.query,
    },
  ]
}

const update = (
  model: Model,
  msg: Message,
  _props: InitProps,
): UpdateReturnType<Model, Message> => {
  switch (msg.name) {
    case "ApiSucceeded":
      return [model]

    case "ApiFailed":
      return [model]

    case "ChangedQuery":
      return [{ ...model, query: msg.query }]

    case "ChangedAppliedQuery":
      return [{ ...model, appliedQuery: msg.query }]

    case "ComponentAutoDeployToggled": {
      const { component, value } = msg

      const onSuccess = (): Message => ({
        name: "ComponentAutoDeployToggledDone",
        component,
      })

      const onFailure = (): Message => ({
        name: "ComponentAutoDeployToggledFailed",
        component,
      })

      return [
        model,
        cmd.ofEither(
          () =>
            componentsApi.toggleAutoDeploy({
              componentName: component.name,
              value,
            }),
          onSuccess,
          onFailure,
        ),
      ]
    }

    case "ComponentAutoDeployToggledDone": {
      // const { component } = msg

      return [model]
    }

    case "ComponentAutoDeployToggledFailed": {
      // const { component } = msg

      return [model]
    }

    case "ComponentDeployed": {
      const { deploymentId } = msg

      const onSuccess = (): Message => ({
        name: "ComponentDeployedDone",
        deploymentId,
      })

      const onFailure = (): Message => ({
        name: "ComponentDeployedFailed",
        deploymentId,
      })

      const nextModel: Model = {
        ...model,
        deploymentStatuses: {
          ...model.deploymentStatuses,
          [deploymentId]: "PENDING",
        },
      }

      return [
        nextModel,
        cmd.ofEither(
          () => componentsApi.deploy({ deploymentId }),
          onSuccess,
          onFailure,
        ),
      ]
    }

    case "ComponentDeployedDone": {
      const { deploymentId } = msg

      const nextModel: Model = {
        ...model,
        deploymentStatuses: {
          ...model.deploymentStatuses,
          [deploymentId]: "SUCCESS",
        },
      }

      return [nextModel]
    }

    case "ComponentDeployedFailed": {
      const { deploymentId } = msg

      const nextModel: Model = {
        ...model,
        deploymentStatuses: {
          ...model.deploymentStatuses,
          [deploymentId]: "ERROR",
        },
      }

      return [nextModel]
    }

    case "ComponentLocked": {
      const { component, value, comment } = msg

      const onSuccess = (): Message => ({
        name: "ComponentLockedDone",
        component,
      })

      const onFailure = (): Message => ({
        name: "ComponentLockedFailed",
        component,
      })

      return [
        model,
        cmd.ofEither(
          () =>
            componentsApi.lock({
              componentName: component.name,
              value,
              comment,
            }),
          onSuccess,
          onFailure,
        ),
      ]
    }

    case "ComponentLockedDone": {
      // const { component } = msg

      return [model]
    }

    case "ComponentLockedFailed": {
      // const { component } = msg

      return [model]
    }
  }
}

type ListItemsProps = {
  appliedQuery: string
  components: ReadonlyArray<Component>
  currentComponentName: string | undefined
}

const ListItems = (props: ListItemsProps) => {
  const { components } = props

  if (components.length === 0) {
    return <div>Empty</div>
  }

  const filter = filterComponent(props.appliedQuery)
  const filtered = components.filter(filter)

  if (filtered.length === 0) {
    return <NoResults title="No Components with that name" />
  }

  const elements = filtered.map((component: Component) => {
    const isActive = component.name === props.currentComponentName

    return (
      <ComponentListItem
        component={component}
        isActive={isActive}
        isLoading={false}
        key={component.name}
      />
    )
  })

  return <>{elements}</>
}

type Props = {
  context: Context
}

export const ComponentList = (props: Props) => {
  const currentComponentName = getComponentName(
    props.context.currentAppLocation,
  )

  const [model, dispatch] = useElmish<InitProps, Model, Message>({
    props: { query: "" },
    init,
    update,
    name: "ComponentList",
  })

  const onAutoDeployComponent = useCallback(
    (component: Component, value: boolean) => {
      dispatch({ name: "ComponentAutoDeployToggled", component, value })
    },
    [dispatch],
  )

  const onDeployComponent = useCallback(
    (deploymentId: string) => {
      dispatch({ name: "ComponentDeployed", deploymentId })
    },
    [dispatch],
  )

  const onLockComponent = useCallback(
    (component: Component, lock: boolean, comment: string) => {
      dispatch({ name: "ComponentLocked", component, value: lock, comment })
    },
    [dispatch],
  )

  const setQueryDebounced = useCallback(
    debounce((query: string) => {
      dispatch({ name: "ChangedAppliedQuery", query })
    }, 200),
    [],
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const query = event.target.value
      dispatch({ name: "ChangedQuery", query })

      setQueryDebounced(query)
    },
    [dispatch, setQueryDebounced],
  )

  const { remoteData } = useQuery({
    url: "/component",
    parse: Schema.decodeUnknownEither(ListResponseSchema),
  })

  if (remoteData._tag === "NotAsked" || remoteData._tag === "Loading") {
    return <Loading classname="pb-12" />
  }

  if (remoteData._tag === "Failure") {
    log.error(remoteData.error)
    return <div>Error</div>
  }

  const { data } = remoteData
  const { components } = data

  const currentComponent = components.find(
    (component) => component.name === currentComponentName,
  )

  const failing: Array<Component> = components.filter(
    (component) => !component.deployPassing,
  )

  return (
    <>
      <Panel className="p-3 mb-3 rounded shadow-sm">
        <TextInput
          className="w-80"
          placeholder="Search components by name..."
          value={model.query}
          onChange={handleChange}
        />
      </Panel>
      <Panel>
        <div className="grid grid-cols-3">
          <div
            className="t-list col-span-1 overflow-y-scroll"
            style={{ maxHeight: "50rem" }}
          >
            <ListItems
              appliedQuery={model.appliedQuery}
              components={components}
              currentComponentName={currentComponentName}
            />
          </div>
          <div
            className="t-details col-span-2 overflow-y-scroll"
            style={{ maxHeight: "50rem" }}
          >
            {currentComponent ? (
              <ComponentItemContent
                component={currentComponent}
                context={props.context}
                deploymentStatuses={model.deploymentStatuses}
                failing={failing}
                isLoading={false}
                onAutoDeployComponent={onAutoDeployComponent}
                onDeployComponent={onDeployComponent}
                onLockComponent={onLockComponent}
              />
            ) : null}
          </div>
        </div>
      </Panel>
    </>
  )
}
