import { PoliciesResponseSchema, type Policy } from "~/models/Policy"
import {
  PolicyAttachementsResponseSchema,
  type PolicyAttachment,
} from "~/models/PolicyAttachment"
import type { RemoteData } from "~/models/RemoteData"
import { Schema } from "@effect/schema"
import { useQuery } from "~/common/hooks/useQuery"

export const useOrganisationPolicies = (
  orgId: string,
): {
  remoteData: RemoteData<{ Policies: ReadonlyArray<Policy> }, unknown>
} => {
  const { remoteData } = useQuery({
    url: `/organisation/${orgId}/policies`,
    parse: Schema.decodeUnknownEither(PoliciesResponseSchema),
  })

  return { remoteData }
}

export const useOrganisationPolicieAttachements = (
  orgId: string,
): {
  remoteData: RemoteData<
    { PolicyAttachments: ReadonlyArray<PolicyAttachment> },
    unknown
  >
} => {
  const { remoteData } = useQuery({
    url: `/organisation/${orgId}/policies/attachments`,
    parse: Schema.decodeUnknownEither(PolicyAttachementsResponseSchema),
  })

  return { remoteData }
}
