import * as usersApi from "~/common/api/usersApiV2"
import React from "react"
import { ButtonOutline } from "~/common/ui/Buttons"
import type { DeploymentStatus } from "~/models/Component"

const IDLE_TIMEOUT = 10000

type Props = {
  userId: string
  organisationId: string
  disabled: boolean
  text: string
}

export const ComponentSendVerifyEmail = (props: Props) => {
  const { userId, organisationId, disabled, text } = props

  const [status, setStatus] = React.useState<DeploymentStatus>("IDLE")

  const handleSendVerifyEmail = (userId: string) => {
    setStatus("PENDING")

    usersApi
      .sendVerifyEmail(organisationId, userId)
      .then(() => setStatus("SUCCESS"))
      .catch(() => setStatus("ERROR"))
      .finally(() => setTimeout(() => setStatus("IDLE"), IDLE_TIMEOUT))
  }

  if (status === "PENDING") {
    return <ButtonOutline disabled={true}>Loading...</ButtonOutline>
  }

  return (
    <ButtonOutline
      onClick={() => handleSendVerifyEmail(userId)}
      disabled={disabled}
    >
      {status === "ERROR" ? "Error!" : text}
    </ButtonOutline>
  )
}
