import * as log from "~/common/utils/log"
import * as routes from "~/routes"
import type * as Sentry from "@sentry/browser"
import type { ResourcesConfig } from "aws-amplify"
import type { OAuthConfig } from "@aws-amplify/core"
import { Option, Either } from "effect"
import { Schema } from "@effect/schema"

const ApiEndpointSchema = Schema.Struct({
  endpoint: Schema.String,
  name: Schema.String,
  region: Schema.String,
})

// This needs to use only JSON types
// ie. No Option
// As it mirrors the config set in the window object
const AwsConfigSchema = Schema.Struct({
  API: Schema.Struct({
    endpoints: Schema.Array(ApiEndpointSchema),
  }),
  Analytics: Schema.Struct({
    disable: Schema.Boolean,
  }),
  Auth: Schema.Struct({
    mandatorySignIn: Schema.Boolean,
    region: Schema.String,
  }),
  Juma: Schema.Struct({
    domainName: Schema.String,
    stage: Schema.String,
  }),
  JumaAuth: Schema.Struct({
    identityPoolId: Schema.String,
    mandatorySignIn: Schema.Boolean,
    region: Schema.String,
    userPoolId: Schema.String,
    userPoolWebClientId: Schema.String,
  }),
  Sentry: Schema.optional(
    Schema.Struct({
      dsn: Schema.String,
      projectName: Schema.String,
    }),
  ),
})

export type AwsConfig = typeof AwsConfigSchema.Type

export type AppConfig = {
  awsConfig: AwsConfig
  authDomain: string
  sentry: Option.Option<Sentry.BrowserOptions>
}

export const getAmplifyConfig = (config: AppConfig): ResourcesConfig => {
  let oauthConfig = getOAuthConfig(config)

  const REST = config.awsConfig.API.endpoints.reduce((acc, endpoint) => {
    // APIRestConfig type from amplify
    const apiRestConfig = {
      endpoint: endpoint.endpoint,
      region: endpoint.region,
    }

    return {
      // biome-ignore lint/performance/noAccumulatingSpread:
      ...acc,
      [endpoint.name]: apiRestConfig,
    }
  }, {})

  return {
    API: {
      REST,
    },
    Auth: {
      Cognito: {
        userPoolClientId: config.awsConfig.JumaAuth.userPoolWebClientId,
        userPoolId: config.awsConfig.JumaAuth.userPoolId,
        identityPoolId: config.awsConfig.JumaAuth.identityPoolId,
        loginWith: {
          email: false,
          username: false,
          oauth: oauthConfig,
        },
      },
    },
  }
}

const getOAuthConfig = (config: AppConfig): OAuthConfig => {
  const redirectSignIn = getRedirectOnSignIn()

  const redirectSignOut = getRedirectOnSignOut()

  return {
    domain: config.authDomain,
    scopes: ["openid", "profile", "email", "aws.cognito.signin.user.admin"],
    redirectSignIn: [redirectSignIn],
    redirectSignOut: [redirectSignOut],
    responseType: "code",
  }
}

const getRedirectOnSignIn = (): string => {
  return routes.generateWithHost(
    window.location.origin,
    routes.routeAuthCallback(),
  )
}

const getRedirectOnSignOut = (): string => {
  return routes.generateWithHost(
    window.location.origin,
    routes.routeAuthSignOut(),
  )
}

const getSentryConfig = (
  awsConfig: AwsConfig,
): Option.Option<Sentry.BrowserOptions> => {
  // biome-ignore lint/complexity/useLiteralKeys: This conflicts with TS noPropertyAccessFromIndexSignature
  if (!import.meta.env["PRO"]) return Option.none()

  const maybeSentry = Option.fromNullable(awsConfig.Sentry)

  return Option.map(maybeSentry, (sentry) => {
    const dsn = sentry.dsn

    const environment = awsConfig.Juma.stage

    // biome-ignore lint/complexity/useLiteralKeys: This conflicts with TS noPropertyAccessFromIndexSignature
    const release = `${sentry.projectName}@${import.meta.env["REACT_APP_STAX_VERSION"]}`

    return {
      dsn,
      environment,
      release,
    }
  })
}

export const parseConfig = (config: unknown) => {
  return Schema.decodeUnknownEither(AwsConfigSchema)(config)
}

export const getConfig = (): AppConfig => {
  const awsConfigResult = parseConfig(window.AWS_CONFIG)

  const awsConfig = Either.getOrThrowWith(awsConfigResult, () => {
    log.error(Either.getLeft(awsConfigResult))
    return new Error("Unable to parse config")
  })

  if (awsConfig.Sentry) {
    log.warn("AWS_CONFIG missing Sentry: ", awsConfig)
  }

  const authDomain = `admin-jumaauth-${awsConfig.Juma.stage}-master.auth.${awsConfig.JumaAuth.region}.amazoncognito.com`

  const sentry = getSentryConfig(awsConfig)

  return { awsConfig, authDomain, sentry }
}
