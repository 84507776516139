import * as log from "~/common/utils/log"
import * as routes from "~/routes"
import { LinkButtonOutline } from "~/common/ui/Buttons"
import { Can, Role } from "~/common/ui/Can"
import type { Context } from "~/models/Context"
import { Page, PageFailure, PageIsLoading } from "~/common/ui/Page"
import { ProvisionBillingRootPool } from "~/widgets/ProvisionBillingRootPool"
import { ProvisionBillingRoots } from "~/widgets/ProvisionBillingRoots"
import { useAccountBillingRoots } from "~/common/hooks/useAccountBillingRoots"

const TITLE = "Provision"

type Props = {
  context: Context
}

export const PageProvision = (props: Props) => {
  const { remoteData: remoteDataUnusedBillingRoots } =
    useAccountBillingRoots("UNUSED")

  if (
    remoteDataUnusedBillingRoots._tag === "Loading" ||
    remoteDataUnusedBillingRoots._tag === "NotAsked"
  ) {
    return <PageIsLoading context={props.context} title={TITLE} />
  }

  if (remoteDataUnusedBillingRoots._tag === "Failure") {
    log.error(remoteDataUnusedBillingRoots.error)
    return (
      <PageFailure
        context={props.context}
        message="Error fetching Accounts"
        title={TITLE}
      />
    )
  }

  const { Accounts: unusedBillingRoots } = remoteDataUnusedBillingRoots.data

  const numActiveBillingRoots = unusedBillingRoots.length

  const actions = (
    <div className="t-provision-links flex space-x-2">
      <Can
        currentUser={props.context.currentUser}
        requiredRole={Role.deployer}
        yes={
          <LinkButtonOutline
            href={routes.generate(routes.RouteProvisionAddBillingRoot())}
          >
            + New Billing Root
          </LinkButtonOutline>
        }
        no={
          <LinkButtonOutline
            title="You aren't allowed to create billing roots"
            href="#"
            disabled={true}
          >
            + New Billing Root
          </LinkButtonOutline>
        }
      />
      <Can
        currentUser={props.context.currentUser}
        requiredRole={Role.customersuccess}
        yes={
          <LinkButtonOutline
            href={routes.generate(routes.routeProvisionVerify())}
            title="Verify Onboarding Status"
          >
            Verify Onboarding
          </LinkButtonOutline>
        }
        no={
          <LinkButtonOutline
            title="You aren't allowed to verify provisioned installations"
            disabled={true}
            href="#"
          >
            Verify Onboarding
          </LinkButtonOutline>
        }
      />
      <Can
        currentUser={props.context.currentUser}
        requiredRole={Role.deployer}
        yes={
          <LinkButtonOutline
            title={
              numActiveBillingRoots === 0
                ? "No available billing roots"
                : "Create a customer"
            }
            disabled={numActiveBillingRoots === 0}
            href={routes.generate(routes.routeProvisionAddCustomer())}
          >
            + New Customer
          </LinkButtonOutline>
        }
        no={
          <LinkButtonOutline
            title="You aren't allowed to create customers"
            disabled={true}
            href="#"
          >
            + New Customer
          </LinkButtonOutline>
        }
      />
      <Can
        currentUser={props.context.currentUser}
        requiredRole={Role.deployer}
        yes={
          <LinkButtonOutline
            title={
              numActiveBillingRoots === 0
                ? "No available billing roots"
                : "Create an organisation"
            }
            disabled={numActiveBillingRoots === 0}
            href={routes.generate(routes.routeProvisionAddOrganisation())}
          >
            + New Organisation
          </LinkButtonOutline>
        }
        no={
          <LinkButtonOutline
            title="You aren't allowed to create organisations"
            disabled={true}
            href="#"
          >
            + New Organisation
          </LinkButtonOutline>
        }
      />
    </div>
  )

  return (
    <Page context={props.context} title={TITLE} actions={actions}>
      <h2 className="mt-4 font-semibold">Available Billing Roots</h2>
      <ProvisionBillingRootPool />

      <h2 className="mt-4 font-semibold">All Billing Roots</h2>
      <ProvisionBillingRoots />
    </Page>
  )
}
